/**
Copyright (C) 2019-2022, Infraclear Inc. All Rights Reserved.
*/

// import WebViewer from "@pdftron/webviewer";
import { ReactElement } from "react";
import { PdfPathUrl } from "../../constants/api-urlConstants";
import { apiBasePathSearch } from "../../constants/env-url";
import './embedded.scss';
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin, DefaultLayoutPluginProps } from "@react-pdf-viewer/default-layout";
import * as PDFJS from 'pdfjs-dist';
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

import { toolbarPlugin, ToolbarProps, ToolbarSlot, TransformToolbarSlot } from '@react-pdf-viewer/toolbar';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';

PDFJS.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${PDFJS.version}/build/pdf.worker.min.js`;

interface Props {
  docUrl?: string;
  disableElements?: string[];
  viewerHeight?: string;
  collapseBookmarkByDefault?: boolean;
}

export const EmbeddedPDF: React.FC<any> = (props: Props) => {
  const { docUrl = '', viewerHeight = "100vh", collapseBookmarkByDefault } = props;
  const urlParams = new URLSearchParams(window.location.search);
  const hashParams = new URLSearchParams(window.location.hash.substring(1));
  const page = hashParams.get('page');
  const agreementId = urlParams.get('agreementId');
  const AgreementDetail: any = agreementId ? { id: agreementId } : JSON.parse(
    localStorage.getItem("selectedAgreement") || "{}"
  );
  const toolbarPluginInstance = toolbarPlugin();
  const { renderDefaultToolbar } = toolbarPluginInstance;
  const transform: TransformToolbarSlot = (slot: ToolbarSlot) => ({
    ...slot,
    Download: () => <></>,
    EnterFullScreen: () => <></>,
    Print: () => <></>,
    Open: () => <></>,
    OpenMenuItem: () => <></>,
    NumberOfPages: () => (
      <>of <slot.NumberOfPages /></>
    ),
  });

  const renderToolbar = (Toolbar: (props: ToolbarProps) => ReactElement) => (
    <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
  );

  let defaultLayout: DefaultLayoutPluginProps = {
    renderToolbar,
  }
  if (!collapseBookmarkByDefault) {
    defaultLayout = {
      ...defaultLayout,
      setInitialTab: () => Promise.resolve(1)
    }
  }

  const defaultLayoutPluginInstance = defaultLayoutPlugin(defaultLayout);

  const url = docUrl ? docUrl :`${apiBasePathSearch}${PdfPathUrl}?fileName=${AgreementDetail.id}`;

  return (
    <div>
      <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${PDFJS.version}/build/pdf.worker.min.js`}>
        <div style={{ height: viewerHeight }}>
          <Viewer
            fileUrl={`${url}`}
            plugins={[defaultLayoutPluginInstance]}
            initialPage={(!isNaN(Number(page)) ? (Number(page) - 1) : 0) || 0}
            enableSmoothScroll
            withCredentials
          />
        </div>
      </Worker>
    </div>
  );
};
