/**
Copyright (C) 2019-2022, Infraclear Inc. All Rights Reserved.
*/


export const MessageText = {


   nofilter_present: 'No results found. Please adjust your search criteria.',

   my_contract: 'My Agreements',
   contracts_not_assigned_to_you: 'No results found',
   begin_approving: 'Begin approving',
   flag_for_review: 'Flag for Review',
   approve_and_proceed: 'Approve and proceed',

   contract_flaged: 'Contract Flaged!',
   contract_approved: 'Contract Approved!',
   party_name_required: 'Party Name is a required field. If the party is not known, please delete the row.',
   party_type_required:'Party type is a required field. If the party is not known, please delete the row.',
   contract_date_required:'Contract date is a required field.',
   contract_name_required:'Contract name is a required field.',
   contract_type_required:'Contract type is a required field.',
   project_name_required:'Project name is a required field',
   geography_required:'Geography is a required field.',
   country_required:'Country is a required field.',
   region_required:'Region is a required field.',
   sector_required:'Sector is a required field.',

   flagging_this_item:'Please provide a reason for flagging this item :',
   the_document_is_not_in_english:'The document is not in English.',
   taking_a_very_long_time_to_load:'The document is taking a very long time to load or not loading at all.',
   incomplete_or_larger_document:'The document appears to be incomplete or part of a larger document.',
   no_a_meterial_contract:'Not a material contract.',
   
   RESET_PASSWORD : 'Password reset successfully', 
   INVALID_RESET_CREDS : 'Invalid credentials please try again',
   INVALID_SEARCH_RESULTS: "Search results are limited to 10000 documents. Add more filters to narrow your search.",
   INVALID_MFA : 'Invalid MFA code', 
   PASSWORD_MUST_FORMAT : 'The password must contain:',
   PASSWORD_MUST_FORMAT_TEXT : 'At least %d characters', 
   PASSWORD_UPPERCASE_FORMAT : 'Uppercase characters (A-Z)',
   PASSWORD_LOWERCASE_FORMAT : 'Lowercase characters (a-z)',
   PASSWORD_DIGITS_FORMAT : 'Digits (0-9)',
   PASSWORD_SPECIAL_CHARACTERS_FORMAT : 'Special characters (!@#$%^&*)',

   VALIDATION_EMAIL_FORMAT: 'Please input your correct email.',
   VALIDATION_WORK_EMAIL_REQUIRED : 'Business email is required.',    
   VALIDATION_EMAIL_REQUIRED : 'Email is required.',    
   VALIDATION_MFA_CODE_REQUIRED : 'Multi-factor Authentication code is required.',
   VALIDATION_PASS_MATCH : 'New Passwords and confirm password must match.',
   VALIDATION_PASS_FORMAT : 'Must Contain %d Characters, One Uppercase, One Lowercase, One Number and One Special Case Character.',
   VALIDATION_OLD_PASS_REQUIRED : 'Old password is required.',
   VALIDATION_PASS_REQUIRED_LOGIN : 'Password is required.',
   VALIDATION_PASS_REQUIRED : 'New password is required.',
   VALIDATION_PASS_INVALID : 'Password format is Invalid.',
   RESET_CODE_REQUIRED : 'Code is required.',
   VALIDATION_FIRSTNAME_REQUIRED : 'First name is required.',
   VALIDATION_FILTERNAME_REQUIRED : 'Filter name is required.',
   VALIDATION_FILTER_REQUIRED : 'Filter is required.',
   VALIDATION_EMAIL_DOMAIN : 'Please use business email address.',
   VALIDATION_MIN_2 : 'Field should have minimum 2 character',
   VALIDATION_LASTNAME_REQUIRED : 'Last name is required.',
   VALIDATION_COMPANY_REQUIRED : 'Company name is required.',
   VALIDATION_DESIGNATION_REQUIRED : 'Designation name is required.',
   VALIDATION_LINKEDIN_REQUIRED : 'Linkedin profile url is not valid.',
   VALIDATION_PHONENUMBER_REQUIRED : 'Phone number is required.',
   VALIDATION_PHONENUMBER_FORMAT : 'Phone number is invalid.',
   VALIDATION_CONFIRM_PASS_REQUIRED : 'Confirm new password is required.',  
   VALIDATION_TITLE_REQUIRED : 'Title is required.',  
   VALIDATION_LOCATION_REQUIRED : 'Country is required.',  
   VALIDATION_PARENTCOMP_REQUIRED : 'Parent Company is required.',  
   VALIDATION_JOBFUN_REQUIRED : 'Job role is required.',  
   PROFILE_UPDATE:'Profile updated successfully',
   LIMIT_EXCEED_PASSWORD : 'Reset limit exceed please try again after some time.', 
   INTERNAL_SERVER_ERROR: 'Internal server error. Please try again after some time.',
   NEW_FILTER_COMB_CREATED:'New Filter combination created',
   VALIDATION_FILTER_NAME_REQUIRED : 'Filter name is required.',
   SAVED_FILTER_DELETE:'Filter deleted.',
   AGREEMENT_SEARCH_PLACEHOLDER:"Search by type of agreement, sponsor, lender or free text",
   PROJECT_SEARCH_PLACEHOLDER:"Search by project name, sector, sub-sector or free text",
   PURCHASE_ORDER_REQUESTED_SUCCESSFULLY:" Purchase Order requested successfully",
   VALIDATION_AUTHORIZER_EMAIL_REQUIRED: 'Authorizer email is required.',
   VALIDATION_AUTHORIZER_NAME_REQUIRED : 'Authorizer name is required.',
   AUTHORIZE_THE_SUBSCRIPTION_HELP_TEXT:'Please provide us the name, email and phone of the individual who can authorize the subscription purchase:',
   ACCOUNT_MANAGER_SALES_CONTACT_HELP_TEXT:'If you are a Refinitiv customer, please provide the name and email of your Account Manager or Sales Contact.',
   THANK_YOU_FOR_INTEREST_TO_PURCHASE:'Thank you for your interest to purchase a subscription of Infraclear.',
   PLEASE_CONFIRM_YOUR_INFORMATION:'Please confirm your information.',
   PURCHASE_OEDER_RECEIVED_HELP_TEXT_ONE:'Your application has been already received by Infraclear for the purchase order.',
   PURCHASE_OEDER_RECEIVED_HELP_TEXT_SECOND:'You will be contacted shortly to confirm all submitted details.',
   PURCHASE_ORDER_REJECT_TEXT_ONE: 'Unfortunately, we were not able to approve your request at this time.',
   PURCHASE_ORDER_REJECT_TEXT_SECOND: 'Please contact us at support@infraclear.com if you have any questions.',
   SPECIAL_CHARACTERS_ARE_NOT_ALLOWED:"Special characters are not allowed.",
   SESSION_EXPIRE_TITLE:'Need more time?',
   SESSION_EXPIRE_HELP_TEXT:"Your session is about to expire.You'll be automatically signed out in ",
   SESSION_EXPIRE_HELP_TEXT_QUA:'To continue your session, click on ',
   RESET_CODE_SENT : 'Reset verification code is sent to your email.',
   RESET_CODE_FAILED : 'Failed to send reset verification code.',
   INVALID_TWOFA_NO_MATCH : 'Two-factor Authentication code is invalid.',
   VALIDATION_TWOFA_CODE_REQUIRED : 'Two-factor Authentication code is required.',
   WENT_WRONG : 'Something went wrong.',
   CONFIRM_DELETE_FILTER: 'Are you sure you want to delete this filter?',
   REPLACE_EXISTING_FILTER_INFO: 'You have reached the limit for saving filters. Please choose an existing filter to replace the current one.',
   FILTER_NAME_NOT_UNIQUE: "Filter name must be unique"
}