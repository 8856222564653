import { IconButton, InputAdornment, TextField } from "@mui/material";
import { Field, Form, Formik } from "formik";
import SearchIcon from "@mui/icons-material/Search";
import { useState } from "react";

import * as React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { EmbeddedPDF } from "../../component/pdfeditor/EmbeddedPdf";
import { apiBasePathSearch } from "../../constants/env-url";
import { PdfPathUrl } from "../../constants/api-urlConstants";

const tabs = [
  { label: 'PDF', value: 'pdf' },
  { label: 'Text', value: 'text' },
];

const HTMLTermSearch = (props: any) => {
  const { s3Key: s3KeyProp, index } = props;
  const urlParams = new URLSearchParams(window.location.search);
  const s3Key = s3KeyProp || urlParams.get('s3Key');
  const defaultView = urlParams.get('defaultTab');

  const [searchString, setSearchString] = useState('');
  const [activetab, setActivetab] = useState(defaultView === 'text' ? tabs[1].value : tabs[0].value);

  React.useEffect(() => {
    if (defaultView !== 'text') {
      const ele: any = document.getElementsByClassName('document-content')?.[index];
      ele.style.display = 'none';
    }
  }, []);

  const handleChange = (value: string) => {
    setActivetab(value);
    onClickTab(value);
  };

  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const isElementInViewport = (el: any) => {
    const rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  const onSearch = () => {
    const searchText = searchString;
    const term: any = document.getElementsByClassName('document-content')?.[index];
    const markRemovedText = term?.innerHTML?.replaceAll('<mark>', '')?.replaceAll('</mark>', '');
    if (searchText) {
      const re = new RegExp(searchText, 'gi');
      const newText = markRemovedText.replace(re, (match: any) => {
        return `<mark>${match}</mark>`;
      });
      term.innerHTML = newText;
      const highlightElement = document.getElementsByTagName('mark');
      const highlightedElements = Array.from(highlightElement);
      if (!highlightedElements.filter(e => isElementInViewport(e))?.length) {
        highlightedElements?.[0]?.scrollIntoView?.({ behavior: 'smooth', block: 'center' });
      }

      highlightedElements.map((ele, index) => {
        if (isElementInViewport(ele)) {
          if ((index + 1) < highlightedElements.length) {
            highlightedElements[index + 1]?.scrollIntoView?.({ behavior: 'smooth', block: 'center' });
          } else {
            highlightedElements?.[0]?.scrollIntoView?.({ behavior: 'smooth', block: 'center' });
          }
        }
      });
    } else {
      term.innerHTML = markRemovedText;
    }
  }

  const onClickTab = (activeTabIndex: string) => {
    const ele: any = document.getElementsByClassName('document-content')?.[index];
    if (ele && activeTabIndex === tabs?.[0]?.value) {
      ele.style.display = 'none';
    } else if (ele && activeTabIndex === tabs?.[1]?.value) {
      ele.style.display = 'flex';
    }
  }

  return (
    <div className="term-search-container">
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <div className="tabs">
            {tabs?.map((tab, index) =>
              <div className={`tab ${activetab === tab.value ? 'active' : ''}`} key={index} onClick={() => handleChange(tab.value)}>{tab.label}</div>
            )}
          </div>
        </Box>
      </Box>
      {activetab === tabs?.[1]?.value &&
        <div className="navigation-panel">
          <Formik
            initialValues={{}}
            onSubmit={() => {}}
          >
            {({ handleSubmit}) => (
              <Form onSubmit={handleSubmit} className='formWidth'>
                <Field
                  as={TextField}
                  margin="normal"
                  fullWidth
                  label={`Search Text`}
                  name="search-term"
                  autoComplete='off'
                  value={searchString}
                  onChange={(e: any) => setSearchString(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    endAdornment: (
                      (<InputAdornment position="end">
                        <IconButton className="searchIconButtoon" type="submit" onClick={onSearch}>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>)
                    )
                  }}
                  size="small"
                />
              </Form>
            )}
          </Formik>
        </div>
      }
      {activetab === tabs?.[0]?.value &&
        <div className="navigation-panel">
          <EmbeddedPDF
            docUrl={`${apiBasePathSearch}${PdfPathUrl}?fileName=${s3Key}`}
            viewerHeight="calc(100vh - 165px)"
          />
        </div>
      }
    </div>
  );
}

export default HTMLTermSearch;
