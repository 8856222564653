import ReactDOM from 'react-dom/client';
import { useEffect, useState } from "react";
import instance from "../../utils/axiosInstance";
import { GetDocument } from "../../constants/api-urlConstants";
import TermFinderWrapper from "./termFinderStyleWrapper";
import { CircularProgress } from '@mui/material';
import { useLocation } from 'react-router-dom';
import HTMLTermSearch from './htmlTermSearch';
import HTMLIndexSearch from './htmlIndexSearch';
import MetaData from './metaData';
import Dropdown from '../../assets/images/Icons/dropdown.svg';

interface TermFinderProps {
  s3Key?: string;
  contractName?: string;
  docId?: string;
  index?: number;
}

const TermFinder: React.FC = (props: TermFinderProps) => {
  const { s3Key: s3KeyByProp, contractName: contractNameByProps, docId, index = 0 } = props;

  const urlParams = new URLSearchParams(window.location.search);
  const location = useLocation();
  const s3Key = s3KeyByProp || urlParams.get('s3Key');
  const contractName = contractNameByProps || urlParams.get('contractName');
  const [htmlString, setHtmlString] = useState<string>();
  const [apiState, setApiState] = useState<{ loading: boolean, error: string }>({ loading: false, error: '' });
  const [tocList, setTOCList] = useState([]);

  useEffect(() => {
    
    setApiState({
      loading: true,
      error: ''
    });

    const fileName = s3Key?.replace('indexed_document.pdf', 'indexed_document.html');
    instance
    .get(`${GetDocument}?fileName=${fileName}`)
    .then((res: any) => {
      let htmlString: string = res?.data;
      if (htmlString) {
        setHtmlString(htmlString);
        setTimeout(() => {
          alterDOMStructure();
          highlightTOContent();
          highlightTOBody();
        }, 100);
      }
      setApiState({
        loading: false,
        error: ''
      });
    }).catch(() => {
      setApiState({
        loading: false,
        error: 'Failed to load html'
      });
    });
  }, []);

  useEffect(() => {
    alterDOMStructure();
    highlightTOContent();
    highlightTOBody();
  }, [location]);

  const alterDOMStructure = () => {
    const doc_body: any = document.getElementById('document_body');
    const parentOfMetaAndIndex = document.createElement('aside');
    parentOfMetaAndIndex.id = 'sidemenu';
    if (!document.getElementById('sidemenu')) {
      doc_body?.parentNode?.insertBefore?.(parentOfMetaAndIndex, doc_body);
      const metaDataEle = document.createElement('div');
      metaDataEle.id = 'meta-data'
      const root = ReactDOM.createRoot(
        metaDataEle as HTMLElement
      );
      root.render(
        <MetaData docId={docId} />
      );
      parentOfMetaAndIndex.appendChild(metaDataEle);
    }

    const documentContent = document.createElement('div');
    documentContent.className = 'document-content';
    const htmlContent = document.querySelector('#document_body .tei-body2');
    if (!document.querySelector(`.${documentContent.className}`)) {
      htmlContent?.parentNode?.insertBefore(documentContent, htmlContent);
      const tableOfContents = document.querySelectorAll('#table-of-contents');
      if (tableOfContents) {
        Array.from(tableOfContents).map(toc => {
          documentContent.append(toc);
        });
      }
      if (htmlContent) {
        documentContent.append(htmlContent);
      }
    }
  }

  const highlightTOContent = () => {
    const hashParams = new URLSearchParams(window.location.hash.substring(1));
    const namedDest = hashParams.get('nameddest');
    if (namedDest) {
      const element: any = document?.querySelector(`#${(namedDest).replace('#', 'li-')}`);
      if (element) {
        element.scrollIntoView();
        const existingSelectedTerms: NodeListOf<HTMLElement> = document.querySelectorAll('.selected_term');
        for (let i = 0 ; i < existingSelectedTerms.length ; i++) {
          existingSelectedTerms[i].className = '';
        }
        element.className = 'selected_term';
      }
    }
    captureTOCNodes();
  }

  const captureTOCNodes = () => {
    insertSearchBoxForIndex();
    const term = document.getElementsByClassName('document-content');
    if (term?.length) {
      insertSearchBoxForTerm();
    }
  }

  const addCollapseBehaviour = () => {
    const tocLists: any = document.querySelectorAll('#table-of-contents');
    Array.from(tocLists).map((toc: any) => {
      toc.className = 'open';
    })

  }

  const insertSearchBoxForIndex = () => {
    addCollapseBehaviour();
    const searchDiv: Element = document.createElement('div');
    searchDiv.id = 'search-wrapper';
    const root = ReactDOM.createRoot(
      searchDiv as HTMLElement
    );

    const tocList = document.querySelectorAll('#toc-list');
    Array.from(tocList).map((toc, index) => {
      if (!toc?.parentNode?.querySelector('#search-wrapper')) {
        root.render(
          <HTMLIndexSearch toc={toc.children || []} index={index} />
        );
        toc?.parentNode?.insertBefore?.(searchDiv, toc);
      }
    });
  }

  const insertSearchBoxForTerm = () => {
    const searchDiv: Element = document.createElement('div');
    searchDiv.id = 'term-search-wrapper';
    const root = ReactDOM.createRoot(
      searchDiv as HTMLElement
    );
    Array.from(document.getElementsByClassName('document-content'))?.map((ele: any, index) => {
      const parentEle = ele?.parentElement;
      if (!parentEle?.querySelector('#term-search-wrapper')) {
        root.render(
          <HTMLTermSearch s3Key={s3Key} index={index} />
        );
        ele?.parentNode?.insertBefore?.(searchDiv, ele);
      }
    })
  }

  const highlightTOBody = () => {
    
    const ele: any = document.getElementById('document_header');
    if (ele) {
      ele.textContent = contractName?.replaceAll('[', '')?.replaceAll(']', '');
    }
    
    const hashParams = new URLSearchParams(window.location.hash.substring(1));
    const namedDest = hashParams.get('nameddest');
    if (namedDest) {
      const element: any = document.querySelector(namedDest);
      if (element) {
        element.scrollIntoView({behavior: "smooth", block: "start", inline: "start"});
        const existingSelectedTerms: NodeListOf<HTMLElement> = document.querySelectorAll('.selected_term_body');
        for (let i = 0 ; i < existingSelectedTerms.length ; i++) {
          existingSelectedTerms[i].className = '';
        }
        element.className = 'selected_term_body';
      }
    }
  }

  return (
    <TermFinderWrapper>
      <div>
        {apiState.loading &&
          <div className="center-align h-screen">
            <CircularProgress size={28} />
          </div>
        }
        {apiState.error ?
          <div className="center-align h-screen">{apiState.error}</div>
          :
          (htmlString &&
            <div
              dangerouslySetInnerHTML={{__html: htmlString}}
            />
          )
        }
      </div>
    </TermFinderWrapper>
  )
}

export default TermFinder;
