import { TextField, Tooltip } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { useState } from "react";
import ResetIcon from '@mui/icons-material/Replay';
import SortIcon from '@mui/icons-material/Sort';
import { any } from "@amcharts/amcharts5/.internal/core/util/Array";

interface HTMLIndexSearchProps {
  toc?: any;
  index: number;
}

const HTMLIndexSearch = (props: HTMLIndexSearchProps) => {

  const { index } = props;

  const [actualTOC] = useState([...Array.from(props.toc)]);
  const [orderBy, setOrderBy] = useState<string>();

  const onSearch = (searchText: any) => {
    const ele = document.querySelectorAll('#toc-list')?.[index];
    const filteredLis = Array.from(actualTOC).filter((li: any) => li?.innerText?.toLowerCase()?.includes(searchText?.toLowerCase()));
    removeChildSearchIndex();
    if (searchText) {
      filteredLis.map((a: any) => ele?.appendChild(a));
    } else {
      Array.from(actualTOC).map((a: any) => ele?.appendChild(a));
    }
  }

  const onClickSort = (value: string = '', reset = false) => {
    const ele = document.querySelectorAll('#toc-list')?.[index];
    const sortedList = [...Array.from(ele?.children || []).sort((a: any, b: any) => {
      let aText = a?.innerText?.toLowerCase();
      let bText = b?.innerText?.toLowerCase();
      return (aText < bText) ? -1 : ((aText > bText) ? 1 : 0);
    })];
    removeChildSearchIndex();
    const indexList = reset ? actualTOC : (value === 'ASC' ? sortedList : sortedList.reverse());
    indexList.map((a: any) => ele?.appendChild(a));
    setOrderBy(value);
  }

  const removeChildSearchIndex = () => {
    const list = document.querySelectorAll('#toc-list')?.[index];
    function removeAllChildNodes() {
      while (list.firstChild) {
        list?.removeChild(list.firstChild);
      }
    }
    removeAllChildNodes();
  }

  return (
    <div className="search-index-wrapper">
      <Formik
        initialValues={{}}
        onSubmit={() => {}}
      >
        {({ handleSubmit}) => (
          <Form onSubmit={handleSubmit} className='formWidth'>
            <Field
              as={TextField}
              margin="normal"
              fullWidth
              label={`Search Index`}
              name="search"
              autoComplete='off'
              onChange={(e: any) => onSearch(e.target.value)}
              InputLabelProps={{ shrink: true }}
              size="small"
            />
          </Form>
        )}
      </Formik>
      <span className="sort" role="button" onClick={() => onClickSort(orderBy === 'ASC' ? 'DESC' : 'ASC')}>
        <Tooltip title={`Sort ${orderBy === 'ASC' ? 'Z-A' : 'A-Z'}`}>
          <SortIcon
            className={`${(orderBy === 'ASC') ? 'asc' : ''}`}
          />
        </Tooltip>
      </span>
      <span className="reset">
        <Tooltip title="Reset">
          <ResetIcon onClick={() => onClickSort('', true)} />
        </Tooltip>
      </span>
    </div>
  );
}

export default HTMLIndexSearch;
