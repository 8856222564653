import Logout from '../../assets/images/faq/logout.png';
import ResetPassword from '../../assets/images/faq/reset-pwd.png';
import MapView from '../../assets/images/faq/map-view.png';
import ModelTable from '../../assets/images/faq/model-table.png';
import AgreementTable from '../../assets/images/faq/agreement-table.png';
import TermFinder from '../../assets/images/faq/term-finder.png';
import AgreementLevelProject from '../../assets/images/faq/agreement-level-project.png';
import FilterOnMap from '../../assets/images/faq/filter-on-map.png';
import SubSectorFilter from '../../assets/images/faq/sub-sector-filter.png';
import SearchAgreementProject from '../../assets/images/faq/search-agreement-project.png';
import FilterAgreementProjectTable from '../../assets/images/faq/filter-agreement-project-table.png';
import FilterAgreementProjectTable2 from '../../assets/images/faq/filter-agreement-project-table-2.png';
import TableFieldGroup from '../../assets/images/faq/table-field-group.png';
import TableCustomize from '../../assets/images/faq/table-customize.png';
import InterestedAgreement from '../../assets/images/faq/interested-agreement.png';
import SearchFinancialAgreement from '../../assets/images/faq/search-financial-agreement.png';
import InterestedModel from '../../assets/images/faq/interested-model.png';
import SampleXLSX from '../../assets/images/faq/sample-xlsx.png';
import TermComaprision1 from '../../assets/images/faq/term-comparision1.png';
import TermComaprision2 from '../../assets/images/faq/term-comparision2.png';

export const generalFAQ = [
  {
    question: 'How do I log out?',
    answers: [
      {
        desc: `To end your session, simply click your name in the upper right-hand corner of the screen and then select “Logout”.`,
        image: Logout
      }
    ]
  },
  {
    question: 'How do I reset my password?',
    answers: [
      {
        desc: `To reset your password, click your name in the upper right-hand corner of the screen. Then, select “Reset Password” and follow the instructions.`,
        image: ResetPassword
      }
    ]
  },
  {
    question: `About our PDF viewer:`,
    answers: [
      {
        desc: `"PDF technology powered by PDFTron SDK copyright © PDFTronTM Systems Inc., 2001-2024, and distributed by Infraclear Inc. under license. All rights reserved."`,
      }
    ]
  },
  {
    question: `About our Spreadsheet viewer:`,
    answers: [
      {
        desc: `"Spreadsheet technology powered by GrapeCity copyright © 2024 GrapeCity, Inc., and distributed by Infraclear Inc. under license. All rights reserved."`,
      }
    ]
  }
];


export const ProjectAndFinancingAgreementsFAQ = [
  {
    questions: [
      {
        question: 'What does the “Map” page show?',
        answers: [
          {
            desc: `The “Map” page displays all our geolocated infrastructure projects for which there are agreements or models available. You can use it to identify projects in a certain locale. Clicking on a dot will reveal metadata about that project, including project descriptions when available.`,
            points: [{
              point : `Some projects don’t yet have dots representing precise geolocation, so the country they are in is highlighted grey instead. Clicking on a country will allow you to filter the Agreement Table or Models Table by that country.`,
            }],
            image: MapView
          },
        ],
      },
      {
        question: `What does the “Model Table” page show?`,
        answers: [
          {
            desc: `The “Model Table” page is a filterable table displaying all of our financial models. The drop-down controls and tables allow you to quickly search the table for models or projects meeting your specific requirements. From each entry in the table at the bottom, you can see relevant metadata about the model and open up a window to view it in.`,
            points: [],
            image: ''
          },
        ],
        notes: [
          {
            desc: `Note: Depending on the applied filters, the table may have multiple pages.`,
            points: [],
            image: ModelTable
          }
        ]
      },
      {
        question: `What does the “Agreement Table” page show?`,
        answers: [
          {
            desc: `The “Agreement Table” page is a filterable table displaying all of our agreements. The six menus allow you to quickly search the table, as do the drop-downs at the top of the page. From each entry in the table, you can view the terms, show the project on the map, or see the agreement itself.`,
            points: [],
            image: ''
          },
        ],
        notes: [
          {
            desc: `Note: Depending on the applied filters, the table may have multiple pages.`,
            points: [],
            image: AgreementTable
          }
        ]
      },
      {
        question: `What does the “Term Finder” page show?`,
        answers: [
          {
            desc: `The “Term Finder” page shows all the terms we have extracted across our database of agreements. Each row represents a term in a contract. You can apply filters based on the controls at the top of the page. You could, for instance, look at all terms across a specific contract, or across a specific type of term for a specific party with multiple contracts.`,
            points: [
              {
                point: `The “Term Header” drop-down shows sections of the document as they appear in the text, and the Key Term Type groups these under key categories.`
              },
              {
                point: `The most common Key Term Types are displayed as rectangles in the tree map visualization in the middle.`
              },
              {
                point: `You can also directly compare terms by selecting two terms and clicking the “COMPARE TERMS” button.`
              }
            ],
            image: ''
          },
        ],
        notes: [
          {
            desc: `Note: Depending on the applied filters, the table may have multiple pages.`,
            points: [],
            image: TermFinder
          }
        ]
      },
      {
        question: `Which projects on the map have agreement/model data available?`,
        answers: [
          {
            desc: `The map filters allow you to only show projects with certain data. You can toggle each filter by clicking the eye in the layers tab. The “Project Geolocations” options will show you our geolocated projects for each category. The “(Reference)” category shows geolocated projects for which we don’t yet have agreements/models. The “Projects by Country” option will highlight all the countries for which we have Agreements or Models (not all projects have precise geolocation yet).`,
            points: [],
            image: AgreementLevelProject
          },
        ],
        notes: []
      },
      {
        question: `What do the colors on the Map mean?`,
        answers: [
          {
            desc: `The colors represent sectors, as per the legend on the left-hand side of the map. When projects are clustered, the color represents the most common sector in the cluster.`,
            points: [],
            image: ''
          },
        ],
        notes: []
      },
      {
        question: `How do I add and remove filters from the Map?`,
        answers: [
          {
            desc: `By Sector:`,
            points: [
              {
                point: `To add a Sector filter, click the sector you want to view on the legend. You can remove a filter by clicking the “X” next to the applied filter in the bar along the top of the page.`
              }
            ],
            image: FilterOnMap
          },
          {
            desc: `By Sector, Subsector, Party and Country:`,
            points: [
              {
                point: `To add filters, click the drop-down arrow on the filter and select the desired option. You can remove a filter by hovering over the drop-down and then clicking the eraser.`
              }
            ],
            image: SubSectorFilter
          },
        ],
        notes: []
      },
      {
        question: `How do I search for a specific agreement, project, model, or term?`,
        answers: [
          {
            desc: `To search for a specific agreement, project, model, or term, use the drop-down menus at the top of each the respective pages to either search directly or add successive filters. You can clear the filters by clicking the X on each of the applied filter chips.`,
            points: [],
            image: SearchAgreementProject
          },
        ],
        notes: []
      },
      {
        question: `How do I add and remove filters from the Agreements, Models, and Term tables?`,
        answers: [
          {
            desc: `To filter, use the drop-down menus and tables to search or scroll within each field and click the value to apply a filter. To remove these filters, click the X on the filter chip.`,
            points: [],
            image: FilterAgreementProjectTable
          },
          {
            desc: ``,
            points: [],
            image: FilterAgreementProjectTable2
          }
        ],
        notes: []
      },
      {
        question: `Can I group the results table on a specific field?`,
        answers: [
          {
            desc: `You can group the results table by any column in the table. To do so, click the three little dots by the column header, and then select “Group by <Column Name>”. You can remove the filter by clicking the same button and then selecting “Stop grouping by <Column Name>”. `,
            points: [],
            image: TableFieldGroup
          },
        ],
        notes: []
      },
      {
        question: `Can I customize the results table?`,
        answers: [
          {
            desc: `The results table can be customized in several ways. You can sort by column, hide a column, or pin it to the left by clicking the three dots next to a column header and selecting the appropriate option. You can also resize and reorder the columns by dragging the headers around.`,
            points: [],
            image: TableCustomize
          },
        ],
        notes: []
      },
      {
        question: `I have found an agreement I am interested in. What now?`,
        answers: [
          {
            desc: `After finding an agreement, you can open that agreement by clicking “View PDF”. If you would like to search for a word or phrase in the document, click the magnifying glass in the upper-right corner to open a document search interface.`,
            points: [],
            image: InterestedAgreement
          },
        ],
      },
      {
        question: `I have found a model I am interested in. What now?`,
        answers: [
          {
            desc: `After you have found a model, click the “Open” link in the View Models column. This will open a spreadsheet containing the financial model. If you select a cell in the model, you can see the value or formula for that cell at the top of the page.`,
            points: [],
            image: SampleXLSX
          }
        ],
      },
      {
        question: `Can I compare terms?`,
        answers: [
          {
            desc: `You can compare terms by selecting two terms in the terms table and then clicking the “COMPARE TERMS” button. This will open a window with each term side-by-side, along with the rest of the document.`,
            points: [],
            image: TermComaprision1
          },
          {
            desc: ``,
            points: [],
            image: TermComaprision2
          }
        ],
        notes: []
      },      
      {
        question: 'How do I log out?',
        answers: [
          {
            desc: `To end your session, simply click your name in the upper right-hand corner of the screen and then select “Logout”.`,
            image: Logout,
            points: [],
          }
        ]
      },
      {
        question: 'How do I reset my password?',
        answers: [
          {
            desc: `To reset your password, click your name in the upper right-hand corner of the screen. Then, select “Reset Password” and follow the instructions.`,
            image: ResetPassword,
            points: [],
          }
        ]
      },
      {
        question: `About our PDF viewer:`,
        answers: [
          {
            desc: `"PDF technology powered by PDFTron SDK copyright © PDFTronTM Systems Inc., 2001-2024, and distributed by Infraclear Inc. under license. All rights reserved."`,
            points: [],
            image: ''
          }
        ]
      },
      {
        question: `About our Spreadsheet viewer:`,
        answers: [
          {
            desc: `"Spreadsheet technology powered by GrapeCity copyright © 2024 GrapeCity, Inc., and distributed by Infraclear Inc. under license. All rights reserved."`,
            points: [],
            image: ''
          }
        ]
      }
    ],
  },
];

export const financialModelFAQ = [
  {
    title: '',
    questions: [
      {
        question: `How do I search the financial models for a project?`,
        answers: [
          {
            desc: `By Project Name:`,
            points: [
              {
                point: `To search by Project Name, click the Project Name bar and type the name or keywords of the project you are looking for. You can clear the search by hovering over the filter and then clicking the eraser.`
              }
            ],
            image: ''
          },
          {
            desc: `By other filter:`,
            points: [
              {
                point: `To add filters, click on the category you want to filter by. This will either provide a drop-down menu or an adjustable slider. For categories with a drop-down, you can search and then select the field you want to view. For categories with a slider, you can drag the endpoints or type them in to limit the results. You can remove a filter by hovering over the filter and then clicking the eraser.`
              }
            ],
            image: SearchFinancialAgreement,
            imageClass: 'max-w-600'
          },
        ],
        notes: []
      },
      {
        question: `I have found a model I’m interested in. What now?`,
        answers: [
          {
            desc: `After you have found a model, click the View Models link. This will take you to a page providing the sectors, IRR, CapEx, and links to the associated models. When you click open, you will see a spreadsheet containing the financial model. If you select a cell in the model, you can see the value or formula for that cell at the top of the page.`,
            points: []
          }
        ],
        notes: [
          {
            desc: `Note: Those with a trial account will only be able be able to see the first 15x15 cells of a model. Full subscribers will have complete access to the models.`,
            points: [],
            image: InterestedModel
          },
          {
            desc: '',
            points: [],
            image: SampleXLSX
          }
        ],
      }
    ]
  }
];
