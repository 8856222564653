/**
Copyright (C) 2019-2022, Infraclear Inc. All Rights Reserved.
*/

export const ErrorCodes = {
    SOFTWARE_TOKEN_MFA : 'SOFTWARE_TOKEN_MFA',
    NEW_PASSWORD_REQUIRED : 'NEW_PASSWORD_REQUIRED',
    MFA_SETUP : 'MFA_SETUP',
    DUPLICATE_USERNAME : 'Duplicate User Name',
    INVALID_MFA_CODE : "INVALID_MFA_CODE",
    LIMIT_EXCEED_FORGOT : "LIMIT_EXCEED",
    INVALID_SECRET_CODE : "Invalid secret code",
    INVALID_USERNAME_PASSWORD : "INVALID_USERNAME_PASSWORD",
    INTERNAL_SERVER_ERROR:'INTERNAL_SERVER_ERROR',
    DUPLICATE_FILTER_NAME:'DUPLICATE_FILTER_NAME',
    INVALID_TWOFA_CODE : "INVALID_TWOFA_CODE",
    FILTER_NAME_NOT_UNIQUE: "FILTER_NAME_NOT_UNIQUE"
  };