/**
Copyright (C) 2019-2022, Infraclear Inc. All Rights Reserved.
*/



export const UserDetails = "/user/detail";
export const LogoutUser = "/user/logout";
export const EditUserDetails = "/user/editProfile";
export const JobFunction = "/onboard/signup/jobFunction";
export const CountriesList = "/onboard/signup/countries";
export const GetSelectedUserDetails = "/user/edit-user/";
export const SendTwoFASignInCode = "/authenticate/sendSignInCode";

export const StaticData="/dashboard/all";   
export const projectCount="/dashboard/project";
export const contactCount="/dashboard/contact";

export const Projects="/projects";
export const Agreements="/contracts";
export const ProjectDetail="/contractsByProject";

export const SubSectorsList="/filters/subSector";
export const SectorsList="/filters/sector";
export const PartyList="/filters/party";
export const PartyTypeList="/filters/partyType";
export const GeographyList="/filters/geography";
export const CountryList="/filters/country";
export const ContractTypeList="/filters/contractType";
export const SponsorList="/filters/sponsor";
export const LenderList="/filters/lender";
export const LegalAdvisorList="/filters/legal-advisor";
export const ResetPassword = "/authenticate/changePassword";

export const CountryListRequestForm="/request-form/countries";
export const SectorsListRequestForm="/request-form/sectors";
export const SubSectorsListRequestForm="/request-form/sub-sectors";
export const ContractTypeListRequestForm="/request-form/contract-types";
export const RequestFormAdd="request-form/document-request/add";
export const GetDocument = "/getDocument"

export const PdfPathUrl="/downloadPDF";
export const DownloadAllowed="/user/downloadAllowed";
export const DownloadXLSX = "/downloadXLSX";

export const SaveFilters ="/filter/save";
export const ListOfFilters ="/filter/user-defined";
export const ListOfPreDefinedFilters ="/filter/pre-defined";
export const DeleteSavedFilter ="/filter";
export const ApplySavedFilter ="/filter/apply";
export const UserTrailDays="/admin/trialDays";
export const GetUserEmail="/admin/emailById";

export const RequestSubscription="/user/requestSubscription";
export const GetSubscriptionRequest="/user/getSubscriptionRequest";
export const JobRole = "/user/jobFunction";
export const HeartBeat ="/admin/heartbeat";
export const Project="/project";
export const InvalidateToken="/user/invalidateToken";
export const ReleaseNotes = "/release/getReleaseDetails";
export const ReleaseNotesPDF = "/downloadReleaseVersion";
export const metaDataDetails = "/dashboard/termFinderDetails";
export const Key = "/key";
export const projectFilter = "/documentSearchFilters";
export const termDetails = "/termDetails";
export const documentCount = "/documentCount";
export const financialModelDetails = "/financialModelDetails";
export const agreementDetails = "/agreementDetails";
export const mapDetails = "/mapDetails";
export const userFilters = "/userFilters";
export const applyUserFilter = "/applyUserFilter";
export const deleteUserSearchFilter = "/deleteUserSearchFilter";
export const calculateAggregate = '/calculateAggregate';
export const auditDocumentSearch = "/auditDocumentSearch";