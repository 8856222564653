import { useEffect, useState } from "react";
import { Box, Card, CircularProgress, Grid } from "@mui/material";
import Select, { components } from 'react-select';
import '../../pages/dashboard/style.scss';

import { apiBasePathSearch } from "../../constants/env-url";
import { MapPage } from "./map";
import { documentCount, mapDetails, projectFilter } from "../../constants/api-urlConstants";
import instance from "../../utils/axiosInstance";
import { sector_color_codes } from "../../utils/constant";
import { FilterValues } from "../../pages/dashboard/findTerms/model";
import { useDebounce } from "use-debounce";
import { ViewStateChangeEvent } from "react-map-gl";
import axios from "axios";
import { isDemoApp } from "../../utils";

const initialFilterValues: FilterValues[] = [
  {
    displayName: 'Country',
    field: 'country',
    options: [],
    searchPage: 'MAP'
  },
  {
    displayName: 'Sector',
    field: 'sector',
    options: [],
    searchPage: 'MAP'
  },
  {
    displayName: 'Sub-sector',
    field: 'subsector',
    options: [],
    searchPage: 'MAP'
  },
  {
    displayName: 'Organizations Named',
    field: 'parties',
    options: [],
    searchPage: 'MAP'
  }
];

const layers = ['agreements', 'models', 'countries', 'reference'];
let pendingRequests: any = {};

const MapDashboard = () => {

  const [referenceData, setReferenceData] = useState([]);
  const [termCount, setTermCount] = useState<any>({});
  const [aggregationBucketPage, setAggregationBucketPage] = useState<any>({});
  const [data, setData] = useState({});
  const [filters, setFilters] = useState<any>({});
  const [appliedDashboardFilters, setAppliedDashboardFilters] = useState<any>([]);
  const [filterOptions, setFilterOptions] = useState<any>({});
  const [inputSearchText, setInputSearchText] = useState<any>({});
  const [debouncedText] = useDebounce(inputSearchText, 1000);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isMapDataLoading, setIsMapDataLoading] = useState<boolean>(false);

  useEffect(() => {
    fetchTermCounts([]);
    fetchDashboardFilterOptions([]);
    fetchMapData([]);
  }, []);

  useEffect(() => {
    const field = Object.keys(debouncedText)?.[0];
    const value = debouncedText?.[field];
    if (field && value) {
      updateSingleFieldFilterOptionsOnSearch(field, value);
    }
  }, [debouncedText]);

  const fetchMapData = (dashboardFilters: any[] = []) => {
    setIsMapDataLoading(true);
    
    layers.map(layer => {
      if (!(layer === 'models' && isDemoApp())) {

        if (pendingRequests[layer]) {
          pendingRequests[layer]?.cancel();
        }
        const source = axios.CancelToken.source();
        pendingRequests[layer] = source;

        const payload = {
          "dashboardFilters": dashboardFilters,
          mapSearchRequestSize: 5000,
          "layer": (layer)?.toUpperCase()
        };
        const mapAPI = `${apiBasePathSearch}${mapDetails}`;
        instance.post(mapAPI, payload, { cancelToken: pendingRequests?.[layer].token }).then((res: any) => {
          delete pendingRequests[layer];

          const data = res.data;
          setData(old => ({
            ...old,
            [layer]: data
          }))
          if (layer === 'reference') {
            setReferenceData(data);
          }
        }).catch((error) => {
          if (axios.isCancel(error)) {
            console.log('Request canceled');
          }
        }).finally(() => {
          setIsMapDataLoading(false);
        });
      }
    });
  }

  const fetchDashboardFilterOptions = (dashboardFilters = []) => {
		const filterAPI = `${apiBasePathSearch}${projectFilter}`;
		[...initialFilterValues].map(filter => {
      const payload: any = {
        field: filter.field,
        documentSearchPage: filter.searchPage,
        dashboardFilters: dashboardFilters,
        aggregationBucketPage: 0,
        bucketSort: 'DOC_COUNT',
        sortOrder: "Desc",
      };
      (filter.field) && instance.post(filterAPI, payload).then((res: any) => {
        const data = res.data;
        setFilterOptions((old: any) => ({
          ...old,
          [filter.field]: data?.filterDetailsList,
        }));
      }).catch(() => {})
		})
	}

  const getSearchPage = (field: string) => {
    return initialFilterValues.find(filter => filter?.field === field)?.searchPage || '';
  }

  const fetchTermCounts = (dashboardFilters = []) => {
    let tabs = ['agreement'];
    if (!isDemoApp()) {
      tabs.push('model');
    }
    tabs.map(value => {
      const payload = {
        dashboardFilters: dashboardFilters,
        documentSearchPage: (value)?.toUpperCase()
      }
      const filterAPI = `${apiBasePathSearch}${documentCount}`;
      instance.post(filterAPI, payload).then((res: any) => {
        setTermCount((old: any) => ({
          ...old,
          [value]: res?.data?.documentCount
        }))
      }).catch(() => {});
    });
  }

  const Option = (props: any) => {
		return (
      props.label && <div>
        <components.Option {...props}>
          <Box sx={{ display: 'flex', gap: 1, cursor: 'pointer' }}>
            <input
              type="checkbox"
              defaultChecked={props.isSelected}
              className="select-checkbox"
            />
            <Box sx={{ display: 'flex', alignItems: 'start', justifyContent: 'space-between', gap: '5px', width: '100%' }}>
              <Box sx={{ overflowWrap: 'break-word' }}>{props.label}</Box>
              {/* <Box>{(props?.data?.filterCount)?.toLocaleString()}</Box> */}
            </Box>
          </Box>
        </components.Option>
        </div>
		);
	};

  const onChangeFilter = (field: any, value: any, item: any, isOnClearAll = false) => {
    const previousFilter = JSON.parse(JSON.stringify(filters));
    const newFilters = isOnClearAll ? {} : {
      ...previousFilter,
      [field]: value
    };
    setFilters(newFilters);
    const unFilteredFields = initialFilterValues.filter((key) => !newFilters?.[key?.field] || (!Object.keys(newFilters).includes(key.field) && newFilters?.[key?.field]));
    const dashboardFilters = isOnClearAll ? [] : Object.keys(newFilters).filter((key) => {
      return newFilters?.[key]?.length > 0;
    }).map((key) => {
      const criterias = newFilters?.[key]?.map((m: any) => m.filterKey) || newFilters?.[key]?.map((m: any) => m.filterValue) || [];
      return (criterias?.length) && {
        field: key,
        filterCriterias: criterias?.filter((a: any) => a),
        elasticCriteria: "INCLUDE"
      }
    });
    setAppliedDashboardFilters(dashboardFilters.length !== 0 ? dashboardFilters : value?.length ? value : []);
    updateFilterOptions(unFilteredFields, dashboardFilters.length !== 0 ? dashboardFilters : value?.length ? value : [], item);
    fetchMapData(dashboardFilters);
  }

  const updateFilterOptions = (unFilteredFields: any[], dashboardFilters: any, item: any) => {
    unFilteredFields.map(filter => {
      fetchFilterOptions(filter, dashboardFilters, 0);
    });
  }
  
  const fetchFilterOptions = (filter: any, dashboardFilters: any, aggregationBucketPage: number, isOnScroll = false) => {
    setIsMapDataLoading(true);
    const filterAPI = `${apiBasePathSearch}${projectFilter}`;
    const payload = {
      field: filter.field,
      dashboardFilters: dashboardFilters,
      documentSearchPage: getSearchPage(filter?.field),
      aggregationBucketPage: aggregationBucketPage,
      bucketSort: 'DOC_COUNT',
      sortOrder: "Desc"
    };
    (filter.field) && instance.post(filterAPI, payload).then((res: any) => {
      const data = res.data;
      setFilterOptions((old: any) => ({
        ...old,
        [filter.field]: [
          ...(isOnScroll ? (old?.[filter?.field] || []) : []),
          ...data?.filterDetailsList
        ],
      }));
      setAggregationBucketPage((old: any) => ({
        ...old,
        [filter.field]: {
          totalAvailable: data?.cardinalCount,
          currentCount: aggregationBucketPage
        }
      }))
    }).catch(() => {}).finally(() => {
      setIsMapDataLoading(false);
    })
  }

  const updateSingleFieldFilterOptionsOnSearch = (field: string, value: string) => {
    setIsLoading(true);
    const filterAPI = `${apiBasePathSearch}${projectFilter}`;
    const payload = {
      field: field,
      dashboardFilters: [],
      wildCardFilterStatus: true,
      wildCardFilterCriteria: value,
      documentSearchPage: getSearchPage(field),
      aggregationBucketPage: 0,
      bucketSort: 'DOC_COUNT',
      sortOrder: "Desc"
    };
    instance.post(filterAPI, payload).then((res: any) => {
      const data = res.data;
      setFilterOptions((old: any) => ({
        ...old,
        [field]: data?.filterDetailsList,
      }));
    }).catch(() => {}).finally(() => {
      setIsLoading(false);
    });
  }

  const onScrollEnd = (field: string) => {
    if ((aggregationBucketPage?.[field]?.currentCount < aggregationBucketPage?.[field]?.totalAvailable) || !aggregationBucketPage?.[field]?.currentCount) {
      const newCount = (aggregationBucketPage?.[field]?.currentCount || 0) + 20;
      fetchFilterOptions({ field: field }, appliedDashboardFilters, newCount, true);
    }
  }

  const onZoomEnd = (event: ViewStateChangeEvent) => {
    const { viewState: { longitude, latitude } } = event;
    layers.map(layer => {
      if (layer !== 'countries') {
        if (!(layer === 'models' && isDemoApp())) {
          fetchMapDataByLayer(longitude, latitude, layer);
        }
      }
    })
  }

  const fetchMapDataByLayer = (longitude: number, latitude: number, layer: string) => {
    setIsMapDataLoading(true);
    let payload = {
      "dashboardFilters": appliedDashboardFilters,
      "latitude": latitude,
      "longitude": longitude,
      "panFilterStatus": true,
      "distance": "5000km",
      mapSearchRequestSize: 2000,
      "layer": (layer).toUpperCase()
    };
    const mapAPI = `${apiBasePathSearch}${mapDetails}`;
    instance.post(mapAPI, payload).then((res: any) => {
      const data = res.data;
      setData((old: any) => ({
        ...old,
        [layer]: [
          ...(layer === 'reference' ? referenceData : (old?.[layer] || [])),
          ...data
        ]
      }))
    }).catch(() => {}).finally(() => {
      setIsMapDataLoading(false);
    });
  }

  return (
    <Box sx={{ height: '100%' }}>
      <Box>
        <section className={`term-dashboard`}>
          <Grid container spacing={2}>
            {initialFilterValues.map((filter, index) => {
              const searchingField = Object.keys(inputSearchText)?.[0];
              return (
                <Grid item lg={3} key={index}>
                  <label className="label">{filter?.displayName}</label>
                  <Select
                    key={`select-${filter?.field}-${index}`}
                    isMulti
                    closeMenuOnSelect= {false}
                    hideSelectedOptions= {false}
                    isLoading={searchingField === filter?.field && isLoading}
                    components={{
                      LoadingIndicator: () => <CircularProgress size={14} sx={{ marginRight: '8px' }} />,
                      Option
                    }}
                    placeholder={`Select ${filter?.displayName?.toLowerCase()}`}
                    options={filterOptions?.[filter?.field] || []}
                    isClearable
                    value={filters?.[filter?.field] || null}
                    onChange={(value) => {
                      const filterValue = value?.length ? value : null;
                      onChangeFilter(filter.field, filterValue, filter.field === 'project' ? null : filter);
                    }}
                    onInputChange={(value, actionMeta) => {
                      if (value) {
                        setInputSearchText({ [filter.field]: value });
                      } else if (!value && actionMeta?.action === "input-change") {
                        setTimeout(() => {
                          fetchFilterOptions(filter, appliedDashboardFilters, 0);
                        }, 1000);
                      }
                    }}
                    onMenuScrollToBottom={() => {
                      onScrollEnd(filter?.field);
                    }}
                    styles={{
                      // @ts-ignore
                      container: (provided: MediaProvider) => ({
                        ...provided,
                        marginTop: '5px',
                      }),
                      // @ts-ignore
                      control: (provided: MediaProvider) => ({
                        ...provided,
                        cursor: 'pointer'
                      }),
                      // @ts-ignore
                      input: (provided: MediaProvider) => ({
                        ...provided,
                        cursor: 'text'
                      }),
                      // @ts-ignore
                      option: (provided: MediaProvider, state: any) => ({
                        ...provided,
                        color: "#000000",
                        backgroundColor: "#ffffff",
                        "&:hover": { backgroundColor: "#1976d2", color: "#ffffff" },
                      }),
                      menu: (baseStyles) => ({ ...baseStyles, zIndex: 10 })
                    }}
                    getOptionLabel={(option: any) => option.filterKey || option.filterValue || ""}
                    getOptionValue={(option: any) => option.filterKey || option.filterValue || ""}
                  />
                </Grid>
              )
            })}
          </Grid>
        </section>
      </Box>
      <Box sx={{ display: 'flex', height: '100vh', paddingX: 2, paddingBottom: 2 }}>
        <Box sx={{ width: '310px', paddingRight: 2 }}>
          <Card sx={{ padding: 2.5, marginBottom: 2, fontSize: 18, textAlign: 'center' }}>
            <Box>Agreement Count</Box>
            <Box sx={{ fontWeight: 600, fontSize: 32 }}>{(termCount?.agreement)?.toLocaleString()}</Box>
          </Card>
          {termCount?.model &&
            <Card sx={{ padding: 2.5, marginBottom: 2, fontSize: 18, textAlign: 'center' }}>
              <Box>Model Count</Box>
              <Box sx={{ fontWeight: 600, fontSize: 32 }}>{(termCount?.model)?.toLocaleString()}</Box>
            </Card>
          }
          <Card sx={{ padding: 2, marginBottom: 2.5, fontSize: 18, textAlign: 'center', height: 'calc(100vh - 263px)', overflow: 'auto' }}>
            {Object.keys(sector_color_codes)?.sort()?.map((sector: any, index: number) => {
              const color = sector_color_codes?.[sector];
              return (
                <Box key={index} sx={{display: 'flex', gap: '5px', justifyContent: 'flex-end', alignItems: 'center', marginBottom: 1 }}>
                  <Box >{sector}</Box>
                  <Box sx={{width: '15px', height: '15px', backgroundColor: `${color}` }}></Box>
                </Box>
              )
            })}
          </Card>

        </Box>
        <Box sx={{ width: 'calc(100% - 310px)', height: '100%' }}>
          <MapPage data={data} onZoomEnd={onZoomEnd} isLoading={isMapDataLoading} />
        </Box>
      </Box>
    </Box>
  );
}

export default MapDashboard;
