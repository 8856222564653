import { PropsWithChildren } from "react";
import './style.scss';

interface TermFinderWrapperProps extends PropsWithChildren {}

const TermFinderWrapper = (props: TermFinderWrapperProps) => {
  const { children } = props;

  return (
    <section className="term-finder-wrapper">
      {children}
    </section>
  );
}

export default TermFinderWrapper;
