import { Box } from "@mui/material";
import { Popup } from "react-map-gl";
import { isDemoApp } from "../../utils";

interface CountryTooltipProps {
  tooltipInfoOnClick?: any;
  onCloseClickedTooltip?: () => void;
}

const CountryTooltip = (props: CountryTooltipProps) => {
  const { tooltipInfoOnClick, onCloseClickedTooltip } = props;

  const popupFieldsOnClick = [
    { label: 'Country', value: tooltipInfoOnClick?.country },
    { label: 'Agreements', value: tooltipInfoOnClick?.country && tooltipInfoOnClick?.agreementStatus ?
      <a target="_blank" href={`/dashboard/agreement?country=${tooltipInfoOnClick?.country}`}>View</a> : '-' 
    },
  ];

  !isDemoApp() && (
    popupFieldsOnClick.push(
      { label: 'Models', value: tooltipInfoOnClick?.country && tooltipInfoOnClick?.modelStatus ?
        <a target="_blank" href={`/dashboard/model-table?country=${tooltipInfoOnClick?.country}`}>View</a> : '-' 
      }
    )
  )

  return (
    <>
      {tooltipInfoOnClick?.lngLat?.lat &&
        <Popup
          latitude={tooltipInfoOnClick?.lngLat?.lat}
          longitude={tooltipInfoOnClick?.lngLat?.lng}
          onClose={() => {
            onCloseClickedTooltip?.();
          }}
          className="map-popup"
          maxWidth="600"
        >
          <Box
            sx={{ padding: '5px', display: 'flex', justifyContent: 'space-between', borderBottom: 1, borderColor: 'grey.600' }}
          >
            <strong>Projects by Country (Agreements and Models)</strong>
          </Box>
          <Box sx={{maxHeight: '250px', overflow: 'auto'}}>
            {popupFieldsOnClick.map((popupField, index) => (
              <Box
                key={index}
                sx={{ padding: '5px', display: 'flex', gap: '5px', borderBottom: 1, borderColor: 'grey.300', ":hover": { backgroundColor: 'grey.200' } }}
              >
                <Box
                  sx={{ width: '100px', color: 'primary.main', fontWeight: 600, cursor: 'pointer' }}
                >
                  {popupField.label}
                </Box>
                <Box sx={{ width: 'calc(100% - 100px)' }}>
                  {popupField.value}
                </Box>
              </Box>
            ))}

          </Box>
        </Popup>
      }
    </>
  );
}

export default CountryTooltip;
