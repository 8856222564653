/**
Copyright (C) 2019-2022, Infraclear Inc. All Rights Reserved.
*/
import React, { useEffect, useRef, useState } from "react";
import "mapbox-gl/dist/mapbox-gl.css";
import {
  Layer,
  NavigationControl,
  Popup,
  Source,
} from "react-map-gl";

import { Map as MapBox } from 'react-map-gl';
import "./map.scss";
import { Backdrop, Box, Card, CircularProgress, IconButton, Tooltip } from "@mui/material";
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import CloseIcon from '@mui/icons-material/Close';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { sector_color_codes } from "../../utils/constant";
import CountryTooltip from "./countryTooltip";
import { isDemoApp } from "../../utils";

const TYPES = {
  MODELS: 'Models',
  AGREEMENTS: 'Agreements',
  REFERENCE: 'Reference'
};

const popupFields = [
  {
    field: 'projectName',
    label: 'Name'
  },
  {
    field: 'sectors',
    label: 'Sector'
  },
  {
    field: 'subSectors',
    label: 'Sub-sector'
  },
  {
    field: 'type',
    label: 'type'
  },
  {
    field: 'description',
    label: 'Description'
  }
];

export const MapPage: React.FC<any> = (props: any) => {

  const token ="pk.eyJ1IjoidGVjaG9wc2NvZGV3YWxsYSIsImEiOiJjbGl3eWIzZWcwMjkzM21sMmo0b2dubzRrIn0.DwMCMWsqQpXPSUSdJBIRUA";
  
  const { data, onZoomEnd, isLoading } = props;
  const [dataLoading, setDataLoading] = useState(false);
  const [showFilters, setShowFilters] = useState(false);  
  const mapRef = useRef<any>(null);
  const [tooltipInfoOnHover, setTooltipInfoOnHover] = useState<any>({});
  const [tooltipInfo, setTooltipInfo] = useState<any>({}); // dots
  const [tooltipInfoOnClick, setTooltipInfoOnClick] = useState<any>({}); // country
  const [filterOptions, setFilterOptions] = useState<any>({
    agreement: { label: 'Project Geolocations (Agreements)', show: true },
    model: isDemoApp() ? null : { label: 'Project Geolocations (Models)', show: true },
    reference: { label: 'Project Geolocations (Reference)', show: false },
    country_layer: { label: 'Projects by Country (Agreements and Models)', show: true },
  });

  useEffect(() => {
    setDataLoading(!(data?.agreements && data?.countries));
  }, [data]);

  const geojson: any = {
    "type": "FeatureCollection",
    "features": [
      ...(
        (filterOptions?.agreement?.show ? data?.agreements?.map((model: any) => {
          return model?.sectors?.map((sector: string) => {
            return {
              'type': 'Feature',
              'geometry': {
                type: 'Point',
                coordinates: [model?.longitude, model?.latitude]
              },
              properties: {
                sector: sector || '',
                ...model,
                type: TYPES.AGREEMENTS
              },
            }
          })
        })?.flat()?.filter((value: any) => value) : []) || []
      ),

      ...(
        (filterOptions?.model?.show && data?.models?.map((model: any) => {
          return model?.sectors?.map((sector: string) => {
            return {
              'type': 'Feature',
              'geometry': {
                type: 'Point',
                coordinates: [model?.longitude, model?.latitude]
              },
              properties: {
                sector: sector || '',
                ...model,
                type: TYPES.MODELS
              },
            }
          })
        })?.flat()?.filter((value: any) => value)) || []
      ),
      
      ...(
        (filterOptions?.reference?.show && data?.reference?.map((reference: any) => {
          return reference?.sectors?.map((sector: string) => {
            return {
              'type': 'Feature',
              'geometry': {
                type: 'Point',
                coordinates: [reference?.longitude, reference?.latitude]
              },
              properties: {
                sector: sector || '',
                ...reference,
                type: TYPES.REFERENCE
              },
            }
          })
        })?.flat()?.filter((value: any) => value)) || []
      )
    ]
  };

  const layerStylePointReference: any = {
    id: 'point_agreement',
    type: 'circle',
    source: "data-points",
    paint: {
      'circle-radius': 5,
      'circle-color': [
        'match',
        ['get', 'sector'],
        ...Object.keys(sector_color_codes).map(sector => (
          [
            sector,
            sector_color_codes[sector]
          ]
        )).flat(),
        /* default color for other categories */
        '#ff0000'
      ]
    }
  };

  const handleMouseMove = (event: any) => {
    if (showFilters) {
      setShowFilters(false);
    }
    const features = mapRef?.current?.queryRenderedFeatures(event.point);
    const myFeature = features.filter((f: any) => f?.source === "data-points");
    if (myFeature?.length && mapRef?.current && mapRef?.current?.getCanvas) {
      mapRef.current.getCanvas().style.cursor = 'pointer';
    } else {
      mapRef.current.getCanvas().style.cursor = 'grab';
    }
    setTimeout(() => {
      setTooltipInfoOnHover({
        ...event,
        ...myFeature?.[0]
      });
    }, 300);

  }

  const onClickMap = (event: any) => {
    const features = mapRef?.current?.queryRenderedFeatures(event.point);
    const myFeature = features.filter((f: any) => f?.source === "data-points");
    if (myFeature?.length && mapRef?.current && mapRef?.current?.getCanvas) {
      mapRef.current.getCanvas().style.cursor = 'pointer';
    } else {
      mapRef.current.getCanvas().style.cursor = 'grab';
    }
    const popupInfo = {
      ...event,
      ...myFeature?.[0]
    };

    const countryFeature = features.filter((f: any) => f?.source === "countries");
    if (countryFeature?.length) {
      const clickedCountry = data?.countries?.find((country: any) => country?.countryCode === countryFeature?.[0]?.properties?.iso_3166_1_alpha_3);
      if (clickedCountry && !popupInfo?.source) {
        if (!tooltipInfoOnClick.source) {
          setTooltipInfoOnClick({
            ...event,
            ...clickedCountry,
            source: 'countries'
          });
        } else {
          setTooltipInfoOnClick({});
          setTimeout(() => {
            setTooltipInfoOnClick({
              ...event,
              ...clickedCountry,
              source: 'countries'
            });
          }, 100);
        }
      } else {
        setTooltipInfoOnClick({});
      }
    } else {
      setTooltipInfoOnClick({});
    }

    if (!tooltipInfo?.source && myFeature?.length) {
      setTooltipInfo(popupInfo);
    } else {
      setTooltipInfo({});
    }
  }

  const onMapLoad = () => {
    mapRef?.current?.zoomOut();
  }

  const uniqueCountry = new Map(
    data?.countries?.map((a: any) => [a.countryCode, a])
  );
  // @ts-ignore
  const uniqueCountries = [...uniqueCountry?.values()];

  const matchExpression = ['match', ['get', 'iso_3166_1_alpha_3']];
  for (const row of (uniqueCountries || [])) {
    matchExpression.push(row['countryCode'], '#bec6cd');
  }
  matchExpression.push('rgba(0, 0, 0, 0)');

  const WORLDVIEW = 'US';
  const worldview_filter = [
    'all',
    ['==', ['get', 'disputed'], 'false'],
    [
      'any',
      ['==', 'all', ['get', 'worldview']],
      ['in', WORLDVIEW, ['get', 'worldview']]
    ]
  ];

  const worldMapLayer: any = {
    'id': 'countries-join',
    'type': 'fill',
    'source': 'countries',
    'source-layer': 'country_boundaries',
    'paint': {
      'fill-color': matchExpression,
      'fill-opacity': 0.6,
      'fill-outline-color': '#000000'
    },
    'filter': worldview_filter
  }

  const onClickShowHideData = (key: string, value: boolean) => {
    setFilterOptions((old: any) => ({
      ...old,
      [key]: {
        ...old[key],
        show: value
      }
    }));
  }

  return (
    <>
      <div className="mapContainer">
        <Box sx={{ position: 'relative', height: '100%' }}>
          <MapBox
            ref={mapRef}
            initialViewState={{
              longitude: 0,
              latitude: 0,
              zoom: 2,
            }}
            // @ts-ignore
            projection={"mercator"}
            renderWorldCopies={true}
            style={{ width: "100%", height: "100%" }}
            mapStyle="mapbox://styles/sumant-8888/clgeq2zj9002r01qqhywj5bex"
            mapboxAccessToken={token}
            onMouseMove={handleMouseMove}
            onClick={onClickMap}
            attributionControl={false}
            minZoom={1.1}
            onZoomEnd={onZoomEnd}
            onLoad={onMapLoad}
          >
            <Box sx={{ position: 'absolute', left: '10px', top: '10px' }} >
              <Card sx={{display: 'flex', alignItems: 'start'}}>
                {!showFilters ?
                  <IconButton sx={{ padding: 0.5 }} onClick={() => setShowFilters(true)}>
                    <ReadMoreIcon />
                  </IconButton>
                  :
                  <Box>
                    <Box
                      sx={{ padding: '5px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', borderBottom: 1, borderColor: 'grey.600' }}
                    >
                      <strong>LAYERS</strong>
                      <IconButton sx={{ padding: 0 }} onClick={() => setShowFilters(false)}>
                        <CloseIcon sx={{ width: '0.8em', height: '0.8em' }} />
                      </IconButton>
                    </Box>
                    <Box sx={{ padding: '5px', fontSize: '14px' }} >
                      {Object.keys(filterOptions).map((key, index) => (
                        filterOptions?.[key]?.label &&
                          <div key={index} className="layer-filter">
                            {filterOptions?.[key]?.label}
                            <div className="action-icon">
                              {filterOptions?.[key]?.show ?
                                <Tooltip title="Hide layer">
                                  <IconButton
                                    onClick={() => onClickShowHideData(key, false)}
                                  >
                                    <VisibilityOff />
                                  </IconButton>
                                </Tooltip>
                                :
                                <Tooltip title="Show layer">
                                  <IconButton onClick={() => onClickShowHideData(key, true)}>
                                    <Visibility />
                                  </IconButton>
                                </Tooltip>
                              }
                            </div>
                          </div>
                        )
                      )}
                    </Box>
                  </Box>
                }
              </Card>
            </Box>

            {isLoading &&
              <Box sx={{ position: 'absolute', right: '50px', top: '10px' }} >
                <Card>
                  <IconButton sx={{ padding: 0.5 }}>
                    <CircularProgress color="primary" sx={{ height: '20px !important', width: '20px !important' }} />
                  </IconButton>
                </Card>
              </Box>
            }

            <Source
              id="data-points"
              type="geojson"
              data={geojson}
            >
              <Layer {...layerStylePointReference} />            
            </Source>

            {data?.countries?.length && filterOptions.country_layer.show &&
              <Source
                id="countries"
                type="vector"
                url="mapbox://mapbox.country-boundaries-v1"
              >
                <Layer beforeId="point_agreement" {...worldMapLayer} />
              </Source>
            }

            <NavigationControl />


            {tooltipInfoOnHover?.source === 'data-points' &&
              <Popup
                latitude={tooltipInfoOnHover?.lngLat?.lat}
                longitude={tooltipInfoOnHover?.lngLat?.lng}
                onClose={() => {
                  setTooltipInfoOnHover({});
                }}
                className="map-popup"
                maxWidth="600"
              >
                <Box
                  sx={{ padding: '5px', display: 'flex', justifyContent: 'space-between', borderBottom: 1, borderColor: 'grey.600' }}
                >
                  <strong>Project Geolocations ({tooltipInfoOnHover?.properties?.type})</strong>
                </Box>
                <Box sx={{maxHeight: '300px', overflow: 'auto'}}>
                  {popupFields.map((popupField, index) => (tooltipInfoOnHover?.properties?.[popupField?.field] !== TYPES.REFERENCE) &&
                    (
                      <Box
                        key={index}
                        sx={{ padding: '5px', display: 'flex', gap: '5px', borderBottom: 1, borderColor: 'grey.300', ":hover": { backgroundColor: 'grey.200' } }}
                      >
                        <Box
                          sx={{ width: '100px', color: 'primary.main', fontWeight: 600, cursor: 'pointer', ":hover": { textDecoration: 'underline' } }}
                        >
                          {popupField?.label === 'type' ? tooltipInfoOnHover?.properties?.[popupField?.field] : popupField?.label}
                        </Box>
                        <Box sx={{ width: 'calc(100% - 100px)' }}>
                          {
                            popupField?.label === 'type' ?
                              ((tooltipInfoOnHover?.properties?.[popupField?.field] === TYPES.AGREEMENTS && tooltipInfoOnHover?.properties?.agreementUrl)
                                ? <a target="_blank" rel="noreferrer" href={`${tooltipInfoOnHover?.properties?.agreementUrl}`}>View</a>
                                : ((tooltipInfoOnHover?.properties?.[popupField?.field] === TYPES.MODELS && tooltipInfoOnHover?.properties?.modelUrl)
                                    ? <a target="_blank" rel="noreferrer" href={`${tooltipInfoOnHover?.properties?.modelUrl}`}>View</a>
                                    : '-'
                                  )
                              )
                              :
                              (['sectors', 'subSectors'].includes(popupField.field)
                                ?
                                tooltipInfoOnHover?.properties?.[popupField?.field] ? JSON.parse((tooltipInfoOnHover?.properties?.[popupField?.field]))?.join?.(', ') : '-'
                                :
                                tooltipInfoOnHover?.properties?.[popupField.field]
                              ) || '-'
                          }
                        </Box>
                      </Box>
                    )
                  )}
                </Box>
              </Popup>
            }

            {tooltipInfo?.source === 'data-points' &&
              <Popup
                latitude={tooltipInfo?.lngLat?.lat}
                longitude={tooltipInfo?.lngLat?.lng}
                onClose={() => {
                  setTooltipInfo({});
                }}
                className="map-popup"
                maxWidth="600"
              >
                <Box
                  sx={{ padding: '5px', display: 'flex', justifyContent: 'space-between', borderBottom: 1, borderColor: 'grey.600', ":hover": { backgroundColor: 'grey.200' } }}
                >
                  <strong>Project Geolocations ({tooltipInfo?.properties?.type})</strong>
                </Box>
                <Box sx={{maxHeight: '300px', overflow: 'auto'}}>
                  {popupFields.map((popupField, index) => (tooltipInfo?.properties?.[popupField?.field] !== TYPES.REFERENCE) &&
                    (
                      <Box
                        key={index}
                        sx={{ padding: '5px', display: 'flex', gap: '5px', borderBottom: 1, borderColor: 'grey.300', ":hover": { backgroundColor: 'grey.200' } }}
                      >
                        <Box
                          sx={{ width: '100px', color: 'primary.main', fontWeight: 600, cursor: 'pointer', ":hover": { textDecoration: 'underline' } }}
                        >
                          {popupField?.label === 'type' ? tooltipInfo?.properties?.[popupField?.field] : popupField?.label}
                        </Box>
                        <Box sx={{ width: 'calc(100% - 100px)' }}>
                          {
                            popupField?.label === 'type' ?
                              ((tooltipInfo?.properties?.[popupField?.field] === TYPES.AGREEMENTS && tooltipInfo?.properties?.agreementUrl)
                                ? <a target="_blank" rel="noreferrer" href={`${tooltipInfo?.properties?.agreementUrl}`}>View</a>
                                : ((tooltipInfo?.properties?.[popupField?.field] === TYPES.MODELS && tooltipInfo?.properties?.modelUrl)
                                    ? <a target="_blank" rel="noreferrer" href={`${tooltipInfo?.properties?.modelUrl}`}>View</a>
                                    : '-'
                                  )
                              )
                              :
                              (['sectors', 'subSectors'].includes(popupField.field)
                                ?
                                tooltipInfo?.properties?.[popupField?.field] ? JSON.parse((tooltipInfo?.properties?.[popupField?.field]))?.join?.(', ') : '-'
                                :
                                tooltipInfo?.properties?.[popupField.field]
                              ) || '-'
                          }
                        </Box>
                      </Box>
                    )
                  )}
                </Box>
              </Popup>
            }
            {(tooltipInfoOnClick?.source === "countries") &&
              !tooltipInfoOnHover?.source &&
              <CountryTooltip
                tooltipInfoOnClick={tooltipInfoOnClick}
                onCloseClickedTooltip={() => setTooltipInfoOnClick({})}
              />
            }
          </MapBox>
        </Box>

        <Backdrop
          sx={{
            color: "#1976D2",
            backgroundColor: "#c0c6cc70",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={dataLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    </>
  );
};
