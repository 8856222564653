import { FINANCIAL_MODELS_ROLES, PROJECT_AND_FINANCING_AGREEMENTS_ROLES } from "./config";

const checkIsRoleAvailable = (eligibleRoles: string[], userRoles: string[]) => eligibleRoles.some(element => userRoles.includes(element));

export const showContracts = (userRoles: string[]) => checkIsRoleAvailable(PROJECT_AND_FINANCING_AGREEMENTS_ROLES, userRoles);

export const showFinancialModels = (userRoles: string[]) => checkIsRoleAvailable(FINANCIAL_MODELS_ROLES, userRoles);

export const removeDuplicateFromStringArray = (array: string[]) => array.filter((value: string, index: number) => array.indexOf(value) === index);

export const isDemoApp = () => ["ce.infraclear.com", "msci.infraclear.com"].includes(window.location.hostname);