import { Grid, CircularProgress, Box, Button, Card, CardContent } from "@mui/material";
import Select from 'react-select';
import React, { useState, useEffect, Fragment, useRef } from "react";
import { Close } from "@mui/icons-material";
import { useDebounce } from "use-debounce";
import { components } from "react-select";
import { GridSortModel } from "@mui/x-data-grid";
import { GridRowGroupingModel } from "@mui/x-data-grid-premium";
import moment from "moment";
import { useNavigate, useSearchParams } from "react-router-dom";

import { FilterValues } from "../findTerms/model";
import instance from "../../../utils/axiosInstance";
import { apiBasePathSearch } from "../../../constants/env-url";
import { documentCount, projectFilter, financialModelDetails, userFilters, applyUserFilter, deleteUserSearchFilter, calculateAggregate } from "../../../constants/api-urlConstants";
import DataTable from "../../../component/datatable/datatable";
import './style.scss';
import { TAB_NAMES } from "../../../utils/constant";
import { Filter, SaveFilterPayload } from "../agreementTable/model";
import SavedFilters from "../agreementTable/filters";
import SaveFilter, { SaveFilterRef } from "../agreementTable/saveFilter";
import { maximumFilterAllowedToSave } from "../../../utils/config";
import { Modal } from "../../../component/Modal";
import CompsTable from "../agreementTable/CompsTable";
import Aggregate from "./aggregate";
import { ErrorCodes } from "../../../constants/messageCodes";
import { CommonContextValueStore } from "../../../constants/commonContext";
import { MessageText } from "../../../constants/messageText";
import { postActivity } from "../trackActivity";

const initialFilterValues: FilterValues[] = [
	{
		displayName: 'Region',
		field: 'region',
		options: []
	},
	{
		displayName: 'Country',
		field: 'country',
		options: []
	},
	{
		displayName: 'Project Name',
		field: 'project_name',
		options: []
	},
	{
		displayName: 'Spreadsheet Content',
		field: 'tags',
		options: []
	},
];

const initialFilterValuesWithCount = [
	{
		displayName: 'Sector',
		field: 'sector',
		options: [],
		bucketSize: 1000
	},
	{
		displayName: 'Sub-sector',
		field: 'subsector',
		options: [],
		bucketSize: 1000
	},
	{
		displayName: 'Parties',
		field: 'parties',
		options: [],
		bucketSize: 10000
	},
]

const ModelTable = () => {
	const { setSnackabrConfig } = React.useContext(CommonContextValueStore);
	const saveFilterRef = useRef<SaveFilterRef>(null);
	const [filters, setFilters] = useState<any>({});
	const [order, setOrder] = useState<any>({});
	const [appliedDashboardFilters, setAppliedDashboardFilters] = useState<any>([]);
	const [filterOptions, setFilterOptions] = useState<any>({});
	const [pageState, setPageState] = useState({
		isLoading: false,
		data: [],
		total: 0,
		page: 1,
		pageSize: 20,
		sortDirection: 'ASC',
    sortField: 'project_name'
	});
	const [inputSearchText, setInputSearchText] = useState<any>({});
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [debouncedText] = useDebounce(inputSearchText, 1000);
	const [inputText, setInputText] = useState<any>({
		sector: '',
		subsector: '',
		parties: ''
	});
  const [sortModel, setSortModel] = useState<GridSortModel>([]);
  const [aggregationBucketPage, setAggregationBucketPage] = useState<any>({});
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [isAdvancedFilterExpanded, setIsAdvancedFilterExpanded] = useState<boolean>(false);
  const [rowGroupingModel, setRowGroupingModel] = useState<GridRowGroupingModel>([]);
  const [tableSearchString, setTableSearchString] = useState<string>('');
	const [showSaveFilterModel, setShowSaveFilterModel] = useState(false);
  const [savedFilters, setSavedFilters] = useState([]);
  const [showSavedFilters, setShowSavedFilters] = useState(false);
	const [aggregateCounts, setAggregateCounts] = useState<{ label: string, count: string }[]>(
		[
			{ label: 'Average IRR', count: '0' },
			{ label: 'Average Capex', count: '$ 0' },
			{ label: 'Average O&M Costs', count: '$ 0' },
		]
	);
	const [showSaveFilter, setShowSaveFilter] = useState(false);

	useEffect(() => {
		const field = Object.keys(debouncedText)?.[0];
		const value = debouncedText?.[field];
		if (field && value) {
			updateSingleFieldFilterOptionsOnSearch(field, value);
		}
	}, [debouncedText]);

	const columns = [
		{
			field: "modelName",
			headerName: "Spreadsheet Name",
			flex: 1,
      hideable: false,
			searchable: true,
			groupable: false,
		},
		{
			field: "projectName",
			headerName: "Project Name",
			flex: 1,
			searchable: true,
			groupable: true,
		},
		{
			field: "country",
			headerName: "Country",
			flex: 0,
			width: 120,
			searchable: true,
			groupable: true,
		},
		{
			field: "sectors",
			headerName: "Sector",
			flex: 1,
			searchable: true,
			groupable: true,
			renderCell: (params: any) => {
				return (
					<div>{params?.formattedValue ? params?.row?.sectors?.join('|') || params?.rowNode?.groupingKey : ''}</div>
				);
			},
		},
		{
			field: "subSectors",
			headerName: "Sub-sector",
			flex: 1,
			searchable: true,
			groupable: true,
			renderCell: (params: any) => {
				return (
					<div>{params?.formattedValue ? params?.row?.subSectors?.join('|') || params?.rowNode?.groupingKey : ''}</div>
				);
			},
		},
		{
      field: "date",
      headerName: "Date",
      flex: 0,
			width: 120,
      searchable: false,
      groupable: false,
      renderCell: (params: any) => {
        return (
          <div>{params?.formattedValue ? moment(params?.row?.date, 'YYYY-MM-DD').format('ll') || '' : ''}</div>
        );
      },
    },
		{
			field: "irrEstimate",
			headerName: "IRR Estimate",
			searchable: false,
			groupable: false,
			flex: 0,
			width: 70,
			renderCell: (params: any) => {
				return (
					<div>{params?.formattedValue ? Math.floor(params?.row?.irrEstimate * 100) + '%' : ''}</div>
				)
			}
		},
		{
			field: "capex",
			headerName: "Capex",
			flex: 0,
			width: 70,
			searchable: false,
			groupable: false,
			renderCell: (params: any) => {
				return (
					<div>{params?.formattedValue ? '$' + Math.floor(params?.row?.capex) + 'm' : ''}</div>
				)
			}
		},
		{
			field: "omCost",
			headerName: "O&M Costs",
			flex: 0,
			width: 70,
			searchable: false,
			groupable: false,
			renderCell: (params: any) => {
				return (
					<div>{params?.formattedValue ? '$' + params?.row?.omCost : ''}</div>
				)
			}
		},
		{
			field: "plantCapacity",
			headerName: "Plant Capacity (MW)",
			flex: 0,
			width: 100,
			searchable: false,
			groupable: false,
			renderCell: (params: any) => {
				return (
					<div>{params?.formattedValue ? Math.floor(params?.row?.plantCapacity) + ' MW' : ''}</div>
				)
			}
		},
		{
			field: "bucketId",
			headerName: "View Model",
			flex: 0,
			width: 90,
      hideable: false,
      sortable: false,
			searchable: false,
			groupable: false,
			renderCell: (params: any) => {
				if (params?.formattedValue) {
					const path = `/financial-model?s3Key=${params.row.bucketId}`;
					const url = `${window.location.origin}${path}`;
					return (
						<a
							href={url}
							target="_blank"
							rel="noreferrer"
							onClick={() => {
								const payload = {
									fileName: params.row.bucketId,
									contractName: '',
									contractDate: params?.row?.date || '',
									contractTypeName: '',
									financialLevel: '',
									projectName: params?.row?.projectName || '',
									country: params?.row?.country || '',
									sectors: params?.row?.sectors.join(', ') || '',
									subSectors: params?.row?.subSectors.join(', ') || '',
									fileUrl: path
								};
								postActivity(payload);
							}}
						>
							Open
						</a>
					);
				} else {
					return '';
				}
			},
		},
	];

	// fetch term filter options
	useEffect(() => {
    const queryFilters = createFilterPayload();
		fetchTermDetails(queryFilters);
		fetchTermCounts(queryFilters);
		fetchTableData(pageState.page, pageState.pageSize, queryFilters);
		fetchSavedFilters();
		fetchAggregateCount();
	}, []);

	const fetchAggregateCount = (dashboardFilters: any[] = []) => {
		const filterAPI = `${apiBasePathSearch}${calculateAggregate}`;
		const payload: any[] = dashboardFilters;
		instance.post(filterAPI, payload).then((res: any) => {
			const response = [
				{ label: 'Average IRR', count: `${res?.data?.irrEstimateAverage}` },
				{ label: 'Average Capex', count: `$${res?.data?.capexAverage}` },
				{ label: 'Average O&M Costs', count: `$${res?.data?.omCostAverage}` },
			];
			setAggregateCounts(response);
		});
	}

	const fetchTermDetails = (dashboardFilters = []) => {
		const filterAPI = `${apiBasePathSearch}${projectFilter}`;
		[...initialFilterValues, ...initialFilterValuesWithCount].map(filter => {
			const payload: any = {
				field: filter.field,
				documentSearchPage: "MODEL",
				dashboardFilters: dashboardFilters,
        aggregationBucketPage: 0,
        bucketSort: 'DOC_COUNT',
        sortOrder: "Desc"
			};
			if (filter.bucketSize) {
				payload.bucketSize = filter.bucketSize;
			}
			(filter.field) && instance.post(filterAPI, payload).then((res: any) => {
				const data = res.data;
				setFilterOptions((old: any) => ({
					...old,
					[filter.field]: data?.filterDetailsList,
				}));
			}).catch(() => {})
		})
	}

	// fetch total terms count
	const fetchTermCounts = (dashboardFilters = []) => {
		const payload = {
			dashboardFilters: dashboardFilters,
			documentSearchPage: "MODEL"
		}
		const filterAPI = `${apiBasePathSearch}${documentCount}`;
		instance.post(filterAPI, payload).then((res: any) => {
			setPageState(old => ({ ...old, total: res?.data?.documentCount }));
		}).catch(() => {});
	}

	const fetchTableData = (page: number, pageSize: number, dashboardFilters: any[], sortField = pageState.sortField, sortDirection = pageState.sortDirection) => {
		const payload = {
			page: page,
			size: pageSize,
			sortField,
      sortDirection,
			dashboardFilters: dashboardFilters
		}
		setPageState((old) => ({ ...old, page, pageSize, sortDirection, sortField }));
		fetchData(payload);
		fetchAggregateCount(dashboardFilters);
	}

	const fetchData = (payload: any) => {
		const filterAPI = `${apiBasePathSearch}${financialModelDetails}`;
		instance.post(filterAPI, payload).then((res: any) => {
			const data = res?.data?.data?.dataList.map((v: any, i: number) => ({ ...v, id: i + 1 })) || [];
			setPageState((old) => ({ ...old, data: data }));
		}).catch(() => {});
	}

	const createFilterPayload = () => {
    const country = searchParams.get('country');
    const project_name = searchParams.get('project_name');

    const queryParamFilters: any[] = [];
		country && addFilters(queryParamFilters, 'country', country);
		project_name && addFilters(queryParamFilters, 'project_name', project_name);
		
		const previousFilter = JSON.parse(JSON.stringify(filters));
		const updatedFilter: any = {};
		project_name && (updatedFilter.project_name = [{
			"filterKey": project_name,
			"filterValue": project_name,
		}]);
		country && (updatedFilter.country = [{
			"filterKey": country,
			"filterValue": country,
		}]);

		if (updatedFilter?.project_name || updatedFilter?.country) {
			const newFilters = {
				...previousFilter,
				...updatedFilter
			};
			setFilters(newFilters);
		}


    setAppliedDashboardFilters(queryParamFilters);
    return queryParamFilters as [];
  }

  const addFilters = (dashboardFilters: any[], field: string, value: string) => {
    dashboardFilters.push({
      field: field,
      filterCriterias: value.split(','),
      elasticCriteria: "INCLUDE"
    })
  }

	const onScrollEnd = (field: string) => {
		if ((aggregationBucketPage?.[field]?.currentCount < aggregationBucketPage?.[field]?.totalAvailable) || !aggregationBucketPage?.[field]?.currentCount) {
      const newCount = (aggregationBucketPage?.[field]?.currentCount || 0) + 20;
      fetchFilterOptions({ field: field }, appliedDashboardFilters, newCount, 'DOC_COUNT', 'DESC', true, true);
    }
	}

	const onChangeFilter = (field: string, value: any, item: any) => {
		const previousFilter = JSON.parse(JSON.stringify(filters));
		const newFilters = {
			...previousFilter,
			[field]: value
		};
		setFilters(newFilters);
		const unFilteredFields = [...initialFilterValues, ...initialFilterValuesWithCount].filter((key) => !newFilters?.[key?.field] || (!Object.keys(newFilters).includes(key.field) && newFilters?.[key?.field]));
		const dashboardFilters: any = Object.keys(newFilters).filter((key) => {
			return newFilters?.[key]?.length > 0;
		}).map((key) => {
			const criterias = newFilters?.[key]?.map((m: any) => m.filterKey) || newFilters?.[key]?.map((m: any) => m.filterValue) || [];
			return (criterias?.length) && {
				field: key,
				filterCriterias: criterias,
				elasticCriteria: "INCLUDE"
			}
		});
		setAppliedDashboardFilters(dashboardFilters);
		updateFilterOptions(unFilteredFields, dashboardFilters, item);
		fetchTermCounts(dashboardFilters);
		fetchTableData(pageState.page, pageState.pageSize, dashboardFilters);
	}

	const updateFilterOptions = (unFilteredFields: any[], dashboardFilters: any[], item: any) => {
		if (item) {
			unFilteredFields.map(filter => {
				if (filter.field) {
					if (filter?.bucketSize) {
						fetchFilterOptions(filter, dashboardFilters, 0, "DOC_COUNT", "DESC", false, false);
					} else {
						fetchFilterOptions(filter, dashboardFilters, 0, 'DOC_COUNT', 'DESC', true, false);
					}
				}
			})
		} else {
			unFilteredFields.map(filter => {
				if (filter?.bucketSize) {
					fetchFilterOptions(filter, dashboardFilters, 0, "DOC_COUNT", "DESC", false, false);
				} else {
					fetchFilterOptions(filter, dashboardFilters, 0, 'DOC_COUNT', 'DESC', true, false);
				}
			})
		}
	}
	
	const fetchFilterOptions = (filter: any, dashboardFilters: any, aggregationBucketPage: number = 0, bucketSort = "DOC_COUNT", sortOrder = "DESC", filterValue = true, isOnScroll = false) => {
		const filterAPI = `${apiBasePathSearch}${projectFilter}`;
		const payload = {
			field: filter.field,
			dashboardFilters: dashboardFilters,
			documentSearchPage: "MODEL",
			bucketSize : filterValue ? 20 : filter.field === 'parties' ? 10000 : 1000,
      aggregationBucketPage,
      bucketSort,
      sortOrder
		};
		(filter.field) && instance.post(filterAPI, payload).then((res: any) => {
			const data = res.data;
      filterValue ? setFilterOptions((old: any) => ({
        ...old,
        [filter.field]: [
          ...(isOnScroll ? (old?.[filter?.field] || []) : []),
          ...data?.filterDetailsList
        ],
      })) : setFilterOptions((old: any) => ({
        ...old,
        [filter.field]: [
          ...data?.filterDetailsList
        ],
      }));
      setAggregationBucketPage((old: any) => ({
        ...old,
        [filter.field]: {
          totalAvailable: data?.cardinalCount,
          currentCount: aggregationBucketPage
        }
      }))
		}).catch(() => {})
	}

	const getLabelForField = (field: string) => {
		return [...initialFilterValues, ...initialFilterValuesWithCount].find(filter => filter.field === field)?.displayName || field;
	}

	const onClickRemoveFilter = (field: string) => {
		onChangeFilter(field, null, field === 'project_name' ? null : field);
		const country = searchParams.get('country');
		const projectName = searchParams.get('project_name');

		if (country || projectName) {
			navigate('/dashboard/model-table');		
		}
	}

	const updateSingleFieldFilterOptionsOnSearch = (field: string, value: string) => {
		setIsLoading(true);
		const filterAPI = `${apiBasePathSearch}${projectFilter}`;
		const payload = {
			field: field,
			wildCardFilterStatus: true,
			wildCardFilterCriteria: value,
			documentSearchPage: "MODEL",
      aggregationBucketPage: 0,
      bucketSort: 'DOC_COUNT',
      sortOrder: "Desc",
      bucketSize : field === 'parties' ? 10000 : 1000,
			dashboardFilters : appliedDashboardFilters,
		};
		instance.post(filterAPI, payload).then((res: any) => {
			const data = res.data;
			setFilterOptions((old: any) => ({
				...old,
				[field]: data?.filterDetailsList,
			}));
		}).catch(() => {}).finally(() => {
			setIsLoading(false);
		});
	}

	const onClickSearch = (options: any) => {
    setTableSearchString(options.value);
    const payload = {
      page: pageState.page,
      size: pageState.pageSize,
      sortField: pageState.sortField,
      sortDirection: pageState.sortDirection,
      dashboardFilters: appliedDashboardFilters,
      wildCardField: options?.field?.field,
      wildCardFilterStatus: true,
      wildCardFilterCriteria: options.value
    }
    fetchData(payload);
  }

  const onSortModelChange = (currentSortModel: GridSortModel) => {
    setSortModel(currentSortModel);
    const field = currentSortModel?.[0]?.field;
    const sort = currentSortModel?.[0]?.sort;
    const fields: any = {
      modelName: "display_name",
      projectName: "project_name",
      country: "country",
      sectors: "sector",
      subSectors: "subsector",
      irrEstimate: "irr_estimate",
      capex: "total_capex_usd",
      omCost: "om_costs_annual_usd",
      plantCapacity: "plant_capacity_mw",
			date: 'date_string'
    };
    if (field && sort) {
      fetchTableData(pageState.page, pageState.pageSize, appliedDashboardFilters, fields?.[field], (sort)?.toUpperCase());
    }
  }

	const Option = (props: any) => {
		return (
			<div>
				<components.Option {...props}>
          <Box sx={{ display: 'flex', gap: 1, cursor: 'pointer' }}>
            <input
              type="checkbox"
              defaultChecked={props.isSelected}
              className="select-checkbox"
            />
            <Box sx={{ display: 'flex', alignItems: 'start', justifyContent: 'space-between', width: '100%' }}>
              <Box sx={{ overflowWrap: 'break-word', maxWidth: '130px' }}>{props.label}</Box>
              <span>{(props?.data?.filterCount)?.toLocaleString()}</span>
            </Box>
          </Box>
				</components.Option>
			</div>
		);
	};

  const onClickClearAllFilter = () => {
    setFilters({});
    setAppliedDashboardFilters([]);
    fetchTermDetails([]);
    fetchTermCounts([]);
    fetchTableData(pageState.page, pageState.pageSize, []);
  }

	const handleSort = (event: any, initialFilterValue: any, bucketSort: string) => {
		event.stopPropagation();
		const newOrder = (order?.[initialFilterValue.field]?.order === 'ASC' ? 'DESC' : 'ASC');
		setOrder((old: any) => ({...old, [initialFilterValue.field]: { key: bucketSort, order: newOrder } }));
		fetchFilterOptions(initialFilterValue, appliedDashboardFilters, 0, bucketSort, newOrder, false, false);
	}

	const handleClearSearch = (field: string) => {
		setTimeout(() => {
			setInputText({...inputText, [field]: '' })
			updateSingleFieldFilterOptionsOnSearch(field, '');
		}, 1100);
	}

  const onClickClearAllTableFilter = () => {
    setRowGroupingModel([]); // reset row grouping
    setSortModel([]); // reset sorting
    setTableSearchString(''); // reset table search

    fetchTableData(pageState.page, pageState.pageSize, appliedDashboardFilters, 'project_name', 'ASC');
  }

	const handleFilterOptionsClick = (field: string, item: any) => {
		setShowSaveFilter(true);
    if (!filters[field]) {
      onChangeFilter(field, [item], item);
    } else if (filters[field] && !filters[field]?.includes(item, 0)) {
      const newFilter = JSON.parse(JSON.stringify(filters));
			newFilter?.[field]?.push?.(item);
			onChangeFilter(field, newFilter?.[field], item);
    };
  }

	const fetchSavedFilters = () => {
    const filterAPI = `${apiBasePathSearch}${userFilters}?filterPage=${TAB_NAMES.MODEL}`;
    instance.get(filterAPI).then((res: any) => {
      const data = res.data || [];
      setSavedFilters(data);
    });
  }

  const fetchFilterBasedOnId = (filterId: string) => {
    const filterAPI = `${apiBasePathSearch}${applyUserFilter}?filterId=${filterId}`;
    instance.post(filterAPI).then((res: any) => {
      const data = res.data;
      const userFilter = JSON.parse(data.userFilter);
      setAppliedDashboardFilters(userFilter.dashboardFilters);

      fetchTermCounts(userFilter.dashboardFilters);
      fetchTableData((userFilter.page + 1), userFilter.size, userFilter.dashboardFilters, userFilter.sortField, userFilter.sortDirection);
      let filters: any = {};
      userFilter?.dashboardFilters?.map((df: any) => {
        filters[df?.field] = df.filterCriterias?.map((x: any) => ({ filterKey: x, filterValue: x }));
      });
      setFilters(filters);

    });
  }

  const onClickSaveFilter = () => {
    setShowSaveFilterModel(true);
  }

  const closeSaveFilterPopup = () => {
    setShowSaveFilterModel(false);
  }

  const onSaveFilter = (values: any) => {
    const payload = {
      page: pageState.page,
      size: pageState.pageSize,
      sortField: pageState.sortField,
      sortDirection: pageState.sortDirection,
      dashboardFilters: appliedDashboardFilters,
      userFilterName: values.filterName,
    }
    saveOrUpdateFilter(payload);
  }

  const onUpdateFilter = (filter: Filter) => {
    const payload = filter.userFilter ? JSON.parse(filter.userFilter) : {};
    payload.userFilterId = filter.id;
    payload.userFilterName = filter.name;
    saveOrUpdateFilter(payload);
  }

  const onReplaceFilter = (filter: { id: number, name: string }) => {
    const payload = {
      page: pageState.page,
      size: pageState.pageSize,
      sortField: pageState.sortField,
      sortDirection: pageState.sortDirection,
      dashboardFilters: appliedDashboardFilters,
      userFilterName: filter.name,
      userFilterId: filter.id,
    }
    saveOrUpdateFilter(payload);
  }

  const saveOrUpdateFilter = (payload: SaveFilterPayload) => {
    const filterAPI = `${apiBasePathSearch}${financialModelDetails}`;
    instance.post(filterAPI, payload).then(() => {
      closeSaveFilterPopup();
      fetchSavedFilters();
    }).catch(error => {
			if (error?.response?.data?.message_code === ErrorCodes.FILTER_NAME_NOT_UNIQUE) {
				setSnackabrConfig({
					type: 'error',
					message: MessageText.FILTER_NAME_NOT_UNIQUE,
					snackFlag: true
				});
				saveFilterRef?.current?.resetLoading();
			}
    }).finally(() => {
      setShowSaveFilter(false);
    });
  }

  const onDeleteFilter = (filter: Filter) => {
    const filterAPI = `${apiBasePathSearch}${deleteUserSearchFilter}?filterId=${filter.id}&filterPage=${TAB_NAMES.MODEL}`;
    instance.post(filterAPI).then(() => {
      fetchSavedFilters();
    });
  }

	return (
		<Fragment>
			<div className="filter-wrapper">
				<Box sx={{ width: '100%' }}>
					{appliedDashboardFilters?.length > 0 &&
						<div className="filters">
							{appliedDashboardFilters?.map((f: any, index: number) => (
								<div key={index} className="filter-value">
									<span><span className="font-bold">{getLabelForField(f.field)}</span>: {f.filterCriterias.join(' | ')}</span> <Close onClick={() => { onClickRemoveFilter(f.field) }} />
								</div>
							))}
						</div>
					}
				</Box>
				<Box sx={{ display: 'flex', gap: '10px' }}>
					{(appliedDashboardFilters?.length > 0) &&
						<Fragment>
							{showSaveFilter &&
								<Button
									sx={{ whiteSpace: 'nowrap' }}
									onClick={() => onClickSaveFilter()}
									variant="contained"
								>
									Save
								</Button>
							}
							<Button
								sx={{ whiteSpace: 'nowrap' }}
								onClick={() => onClickClearAllFilter()}
								variant="outlined"
							>
								Clear All
							</Button>
						</Fragment>
					}
					<Button
						sx={{ whiteSpace: 'nowrap' }}
						onClick={() => savedFilters?.length ? setShowSavedFilters(true) : {}}
						variant="contained"
					>Saved Filters {savedFilters?.length ? `(${savedFilters?.length})` : '(0)'}
					</Button>
				</Box>
			</div>
			
      <Grid container spacing={1.5} padding={'15px'}>
				<Aggregate
					count={(pageState.total).toString()}
					label="Models"
				/>
        {aggregateCounts.map((value, index) => (
          <Aggregate
						key={`card-item-${index}`}
						count={value.count}
						label={value.label}
					/>
        ))}
      </Grid>

			<section className="term-dashboard">
				<Box sx={{ display: 'flex', gap: '15px' }}>
					<Box sx={{ width: 'calc(100%)' }}>
						<Grid container spacing={1.5}>
							{initialFilterValues.map((filter, index) => {
								const searchingField = Object.keys(inputSearchText)?.[0];
								return (
									<Grid item lg={3} key={index}>
										<label className="label">{filter?.displayName}</label>
										<Select
											isMulti
											closeMenuOnSelect={false}
											hideSelectedOptions={false}
											isLoading={searchingField === filter?.field && isLoading}
											components={{
												LoadingIndicator: () => <CircularProgress size={14} sx={{ marginRight: '8px' }} />,
												Option,
											}}
											placeholder={`Select ${filter?.displayName?.toLowerCase()}`}
											options={filterOptions?.[filter?.field] || []}
											isClearable
											value={filters?.[filter?.field] || null}
											onChange={(value) => {
												const filterValue = value?.length ? value : null;
												onChangeFilter(filter.field, filterValue, filter.field === 'project_name' ? null : filter);
												setShowSaveFilter(true);
											}}
											onInputChange={(value, actionMeta) => {
												if (value) {
													setInputSearchText({ [filter.field]: value });
												} else if (!value && actionMeta?.action === "input-change") {
													setTimeout(() => {
														updateSingleFieldFilterOptionsOnSearch(filter.field, value);
													}, 1000);
												}
											}}
											onMenuScrollToBottom={() => {
												onScrollEnd(filter?.field);
											}}
											styles={{
												container: (provided: any) => ({
													...provided,
													marginTop: '5px',
												}),
												control: (provided: any) => ({
													...provided,
													cursor: 'pointer'
												}),
												input: (provided: any) => ({
													...provided,
													cursor: 'text'
												}),
												option: (provided: any, state: any) => ({
													...provided,
													color: "#000000",
													backgroundColor: "#ffffff",
													"&:hover": { backgroundColor: "#1976d2", color: "#ffffff" },
												}),
												menu: (baseStyles) => ({ ...baseStyles, zIndex: 10 })
											}}
											getOptionLabel={(option: any) => option.filterKey || option.filterValue || ""}
											getOptionValue={(option: any) => option.filterKey || option.filterValue || ""}
										/>
									</Grid>
								)
							})}
						</Grid>
					</Box>
				</Box>

				<CompsTable
          handleSort={handleSort}
          order={order}
          filterOptions={filterOptions}
          updateSingleFieldFilterOptionsOnSearch={updateSingleFieldFilterOptionsOnSearch}
          handleClearSearch={handleClearSearch}
          handleFilterOptionsClick={handleFilterOptionsClick}
          compsFilters={initialFilterValuesWithCount}
        />

        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '10px' }}>
          <Button
            sx={{ whiteSpace: 'nowrap' }}
            onClick={() => onClickClearAllTableFilter()}
            variant="contained"
            disabled={!rowGroupingModel.length && !sortModel.length}
          >
						Reset
					</Button>
        </Box>

				<DataTable
          // @ts-ignore
          disableColumnFilter
					dataGridColumn={columns}
					tableConfig={pageState}
					onPageChangeCallback={(pageValue: any) => {
						const page = pageValue + 1;
						setPageState((old) => ({ ...old, page: page }));
						fetchTableData(page, pageState.pageSize, appliedDashboardFilters);
					}}
					onPageSizeCallback={(pageSizeValue: any) => {
						setPageState((old) => ({ ...old, pageSize: pageSizeValue }));
						fetchTableData(pageState.page, pageSizeValue, appliedDashboardFilters);
					}}
					handleClick='projectDetails'
					navigateTab={undefined}
					rowHeight={45}
					// @ts-ignore
					getDetailPanelContent={({ row }) => (
						<Box sx={{ p: 2 }}>
							<div className="label">Parties</div>
							<div>{row.parties?.join(' | ')}</div>
						</Box>
					)}
					getDetailPanelHeight={() => 150}
          sortModel={sortModel}
          onSortModelChange={onSortModelChange}
					showFooterMessage={pageState?.total > 10000}
          rowGroupingModel={rowGroupingModel}
          onRowGroupingModelChange={(model: any) => {
            setRowGroupingModel(model)
          }}
				/>
			</section>

			<Modal
        title={(savedFilters.length < maximumFilterAllowedToSave) ? 'Save Filter' : "Replace Existing Filter"}
        open={showSaveFilterModel}
        onClose={() => closeSaveFilterPopup()}
        bodyClassName="hide-body"
      >
        <SaveFilter
					ref={saveFilterRef}
          onSaveFilter={onSaveFilter}
          onReplaceFilter={onReplaceFilter}
          onCancel={() => closeSaveFilterPopup()}
          savedFilters={savedFilters}
        />
      </Modal>
      <Modal
        title="Saved Filters"
        open={showSavedFilters}
        onClose={() => setShowSavedFilters(false)}
      >
        <SavedFilters
          savedFilters={savedFilters}
          onCancel={() => setShowSavedFilters(false)}
          onSelectFilter={(filter) => {
            setShowSavedFilters(false);
            fetchFilterBasedOnId(filter.id);
						setShowSaveFilter(false);
          }}
          onUpdateFilter={onUpdateFilter}
          onConfirmDelete={onDeleteFilter}
        />
      </Modal>
		</Fragment>
	)
}

export default ModelTable;
